import {IonContent, IonPage} from '@ionic/react';
import React from "react";
import Api from "../../services/Api";
import {CustomerInterface} from "../../services/interfacesApi";
import Header from "../../components/header/Header";

interface ComponentState {
  loaded: boolean
}

export default class SelectCustomer extends React.Component<{}, ComponentState>  {
  api = Api.getInstance()

  state = {
    loaded: false
  }

  componentDidMount() {
    this.api.get('v1/customers').then(response => {
      this.api.profile.customers = response.json.customers
      this.setState({
        loaded: true
      })
    })
  }
  selectCustomer = (customer: CustomerInterface) => {
    this.api.get('v1/select/' + customer.id).then(response => {
      this.api.profile.userDepartments = response.json.userDepartments
      this.api.selectCustomer(customer)
    })
  }

  render () {
    return (
      <IonPage>
        <IonContent>
          <Header name="Select customer"/>
          <div className="page_content">
            <div className="page_section">
              <h1>Select customer</h1>
              {
                this.state.loaded ?
                  <>
                    {
                      this.api.profile?.customers.map((customer: CustomerInterface) => {
                        return (
                          <h2 key={"customer_" + customer.id} className="mt-4 underline cursor-pointer"
                              onClick={() => this.selectCustomer(customer)}>
                            {customer.customerName}
                          </h2>
                        )
                      })
                    }
                  </>
                  :
                  <h2 className="mt-4">Loading...</h2>
              }
            </div>
          </div>
        </IonContent>
      </IonPage>
    )
  }
}
