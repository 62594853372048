export const colors = ['#BC80F0', '#5B6EF4', '#FF9B8A', '#60B9A5']
export const colorsStart = ['#D0A6F5', '#8C9AF7', '#FFB9AD', '#90CEC0']
export const colorsDesired = ['#A46ED2', '#4D5DCD', '#E3806F', '#509E8D']
export const colorAll = '#9999A8'
export const colorAllCompare = '#777798'
export const groupName = ['Main group', 'Group B', 'Group C', 'Group D']
export const groupNameShort = ['Main', 'B', 'C', 'D']

export interface AreaNumberInterface {
  name: string
  nr: number
  questions: number
}

export const getArrow = (group: number, from: number, to: number) => {
  const change = to - from
  const direction = change < 0 ? "down": "up"
  return "/assets/icon/heatmap/arrow_" + group + "_" + direction + ".svg"
}

export const areas: Array<AreaNumberInterface> = [
  {
    name: 'Total',
    nr: -1,
    questions: 52
  },
  {
    name: 'Self insight',
    nr: 1,
    questions: 6,
  },
  {
    name: 'Basic energy',
    nr: 3,
    questions: 10
  },
  {
    name: 'Motivation',
    nr: 0,
    questions: 18
  },
  {
    name: 'Self leadership',
    nr: 2,
    questions: 18
  },
  {
    name: 'Compare all',
    nr: 4,
    questions: 52
  }
]

export const areaPosFromNr = (nr: number) => {
  // Nr is the url-numbering (0 = Total, 1 = Self insight, etc. Translate to area position (0-3)
 if (nr === 1) {
    // Self leadership has position 1
    return 1
  } else if (nr === 2) {
    // Basic energy has position 3
    return 3
  } else if (nr === 3) {
    // Motivation has position 0
    return 0
  } else if (nr === 4) {
    // Self leadership has position 2
    return 2
  }
  // Should not happen
  return -1
}

export const areaNrFromPos = (pos: number) => {
  // Nr is the url-numbering (0 = Total, 1 = Self insight, etc. Translate from area position (0-3) to url number
  if (pos === 0) {
    // Motivation has nr 3
    return 3
  } else if (pos === 1) {
    // Self insight has nr 1
    return 1
  } else if (pos === 2) {
    // Self leadership has nr 4
    return 4
  } else if (pos === 3) {
    // Basic energy has nr 2
    return 2
  }
  // Should not happen
  return -1
}
