import {IonCol, IonContent, IonGrid, IonPage, IonRow} from '@ionic/react';
import React from "react";
import {changeUrl} from "../../services/Navigation";
import Header from "../../components/header/Header";
import Api from "../../services/Api";

interface LicenseInterface {
  clients: number
  packageName: string
}

interface ComponentState {
  licenses: Array<LicenseInterface>
  loaded: boolean
  clients: number
  focusAreas: number
  focusTasks: number
  measures: number
  coachRequests: number
  meetingsDone: number
  meetingsBooked: number
  licenseClients: number
}

export default class Overview extends React.Component<{}, ComponentState>  {

  api = Api.getInstance()

  state = {
    licenses: [],
    loaded: false,
    clients: 0,
    focusAreas: 0,
    focusTasks: 0,
    measures: 0,
    coachRequests: 0,
    meetingsDone: 0,
    meetingsBooked: 0,
    licenseClients: 0,
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    this.api.get('v1/overview/' + this.api.profile.customer?.id).then(response => {
      this.api.profile.userDepartments = response.json.userDepartments
      this.api.storeProfile()
      this.setState({
        licenses: response.json.licenses,
        loaded: true,
        clients: response.json.clients,
        focusAreas: response.json.focusAreas,
        focusTasks: response.json.focusTasks,
        measures: response.json.measures,
        coachRequests: response.json.coachRequests,
        meetingsBooked: response.json.meetingsBooked,
        meetingsDone: response.json.meetingsDone,
        licenseClients: response.json.licenseClients
      })
    })
  }

  render () {
    return (
      <IonPage>
        <IonContent>
          <Header name="Overview" reload={this.getData}/>
          <div className="page_content">
            <div className="page_section">
              <h1>{this.api.profile.customer?.customerName}</h1>

              <div className="mt-12">
                <div style={{ maxWidth: '605px' }} className="page_title_bar" />

                <div style={{ maxWidth: '605px' }} className="flex justify-between">
                  <div className='right_margin'>
                    <h2>Heatmap</h2>
                    <div className='mt-2'>See your group's heatmap.</div>
                  </div>
                  <div className='align-center'>
                    <div className='button white inline_block' onClick={() => changeUrl('/heatmap')}>
                      Read more
                    </div>
                  </div>
                </div>
                <div style={{ maxWidth: '605px' }} className="page_title_bar" />

              </div>

              <div className="mt-4">
                <div style={{ maxWidth: '605px' }} className="flex justify-between">
                  <div className='right_margin'>
                    <h2>Users</h2>
                    <div className='mt-2'>See your users' status.</div>
                  </div>
                  <div className='align-center'>
                    <div className='button white inline_block' onClick={() => changeUrl('/users')}>
                      Read more
                    </div>
                  </div>
                </div>
                <div style={{ maxWidth: '605px' }} className="page_title_bar" />
              </div>

              {/*{*/}
              {/*  this.state.loaded &&*/}
              {/*    <div className="r-flex top_margin">*/}
              {/*      <div className="page_half_section bg_light_green p-4 pb-6 border_radius">*/}
              {/*        <h2>User statistics</h2>*/}
              {/*        <IonGrid>*/}
              {/*            <IonRow>*/}
              {/*                <IonCol size="12" sizeLg="6">*/}
              {/*                  <h3>Total users: {this.state.clients}</h3>*/}
              {/*                  {*/}
              {/*                    this.state.licenses.map((license: LicenseInterface, i) => {*/}
              {/*                      return (*/}
              {/*                        <div key={"license_" + i}>*/}
              {/*                          {license.packageName} license: {license.clients}*/}
              {/*                        </div>*/}
              {/*                      )*/}
              {/*                    })*/}
              {/*                  }*/}
              {/*                  {*/}
              {/*                    (this.state.licenseClients < this.state.clients) &&*/}
              {/*                      <div>Inactive license: {(this.state.clients - this.state.licenseClients)}</div>*/}
              {/*                  }*/}
              {/*                </IonCol>*/}
              {/*                <IonCol size="12" sizeLg="6">*/}
              {/*                    <h3>Measures</h3>*/}
              {/*                    <p>Total: {this.state.measures}</p>*/}
              {/*                    <p>Average per*/}
              {/*                        user: {(this.state.measures / this.state.clients).toFixed(1)}*/}
              {/*                    </p>*/}
              {/*                </IonCol>*/}
              {/*            </IonRow>*/}
              {/*            <IonRow className="top_margin">*/}
              {/*                <IonCol size="12" sizeLg="6">*/}
              {/*                  <h3>Coach meetings</h3>*/}
              {/*                  <p>Meetings done: {this.state.meetingsDone}</p>*/}
              {/*                  <p>Upcoming meetings: {this.state.meetingsBooked}</p>*/}
              {/*                </IonCol>*/}
              {/*                <IonCol size="12" sizeLg="6">*/}
              {/*                    <h3>Active developmental areas</h3>*/}
              {/*                    <p>Total: {this.state.focusAreas}</p>*/}
              {/*                    <p>Average per*/}
              {/*                        user: {(this.state.focusAreas / this.state.clients).toFixed(1)}*/}
              {/*                    </p>*/}
              {/*                </IonCol>*/}
              {/*            </IonRow>*/}
              {/*        </IonGrid>*/}
              {/*      </div>*/}

              {/*      <div className="r-ml-4 r_top_margin">*/}
              {/*        {*/}
              {/*          (this.state.coachRequests > 0) &&*/}
              {/*            <div className="border_radius bg_pink_blue p-4">*/}
              {/*                <p>Coaching</p>*/}
              {/*                <h3 className="mt-4">{this.state.coachRequests} out*/}
              {/*                    of {this.state.clients} users want a coach!</h3>*/}
              {/*            </div>*/}
              {/*        }*/}
              {/*        {*/}
              {/*          (this.state.focusTasks > 0) &&*/}
              {/*            <div className="border_radius bg_blue p-4 top_margin">*/}
              {/*                <h3>Ongoing/completed<br/>coach questions: {this.state.focusTasks}</h3>*/}
              {/*                <p className="mt-4">*/}
              {/*                    Average: {(this.state.focusTasks / this.state.clients).toFixed(1)} per*/}
              {/*                    user*/}
              {/*                </p>*/}
              {/*            </div>*/}
              {/*        }*/}
              {/*      </div>*/}
              {/*    </div>*/}

              {/*}*/}
            </div>
          </div>
        </IonContent>
      </IonPage>
    )
  }
}
