import React from "react";

export const renderSpread = (min: number, max: number, color: string, unit: string) => {
  const leftMin = min < 0 ? 0 : min
  const left = leftMin + '%'
  const width = (max - min) + '%'
  const leftText = (leftMin - 6) + '%'
  const right = max + '%'
  return (
    <div className="flex">
      <div className="font_smaller color_dark_gray mr-2">0</div>
      <div className="relative bg_border_gray border_radius6 bar_height w-full" style={{marginTop: "3px"}}>
        <div className="absolute bar_height" style={{left: left, width: width, backgroundColor: color}}/>
        <div className="absolute" style={{left: left, top: "-4px", height: "20px", width: "1px",
          borderLeft: "1px dashed #000"}}/>
        <div className="absolute" style={{left: right, top: "-4px", height: "20px", width: "1px",
          borderLeft: "1px dashed #000"}}/>
        <div className="absolute font_smaller bold" style={{left: leftText, top: "16px"}}>{min.toFixed(0)}{unit}</div>
        <div className="absolute font_smaller bold" style={{left: right, top: "16px"}}>{max.toFixed(0)}{unit}</div>
      </div>
      <div className="font_smaller color_dark_gray ml-2">100</div>
    </div>
  )
}

export const renderSpreadNegative = (min: number, max: number, color: string, unit: string) => {
  const l = 50 + min / 2
  const left = l + '%'
  const minText = min.toFixed(0) + unit
  const maxText = max.toFixed(0) + unit
  const width = (max - min) / 2 + '%'
  const redWidth = -min / 2 + '%'
  const leftText = (l - minText.length * 2.5) + '%'
  const right = 50 + max / 2 + '%'
  const rightText = 52 + max / 2 + '%'
  const bgMin = 'repeating-linear-gradient(-45deg, #e5e5e9, ' + color + ' 3px)'
  return (
    <div className="flex">
      <div className="font_smaller color_dark_gray mr-2">-100</div>
      <div className="relative bg_border_gray border_radius6 bar_height w-full"  style={{marginTop: "3px"}}>
        <div className="absolute bar_height" style={{left: left, width: width, backgroundColor: color}}/>
        {
          min < 0 &&
            <div className="absolute bar_height" style={{left: left, width: redWidth, background: bgMin}}/>
        }
        <div className="absolute" style={{left: left, top: "-4px", height: "20px", width: "1px",
          borderLeft: "1px dashed #000"}}/>
        <div className="absolute" style={{left: right, top: "-4px", height: "20px", width: "1px",
          borderLeft: "1px dashed #000"}}/>
        <div className="absolute" style={{left: "50%", top: "-4px", height: "20px", width: "1px",
          borderLeft: "1px solid #000"}}/>
        <div className="absolute font_smaller bold" style={{left: leftText, top: "16px"}}>{minText}</div>
        <div className="absolute font_smaller bold" style={{left: rightText, top: "16px"}}>{maxText}</div>
        <div className="absolute font_smaller color_dark_gray" style={{left: "49%", top: "-24px"}}>0</div>
      </div>
      <div className="font_smaller color_dark_gray ml-2">100</div>
    </div>
  )
}
