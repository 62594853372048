// let host = window.location.host.split(':')[0];
// let api_endpoint = "http://" + host + ":8000/customer/"
// let home_page = "http://" + host + "/"
// let system = "develop"

// // get demo from host (customer.demo.zebrain.se) with regex
// system = host.match(/customer\.(.*)\.zebrain\.se/)
// api_endpoint = host.replace('customer', 'api')
// home_page = `https://{system}.zebrain.se/`

let host = window.location.host.split(':')[0];

// get demo from host (customer.demo.zebrain.se) with regex
let m = host.match(/app\.(.*)\.zebrain\.se/)

let system = "";
if (m === null) {
    system = "prod"
} else {
    system = m[1]
}

let api_endpoint = "https://" + host.replace('customer', 'api') + "/customer/"
let home_page = `https://www.zebrain.se/`

export interface AppConfig {
    API_ENDPOINT: string,
    VERSION: string,
    NAME: string,
    TOKEN_NAME: string,
    SESSION_DATA: string,
    ENV: string,
    HOME_PAGE: string,
    SYSTEM: string,
}

export const CONFIG: AppConfig = {
    API_ENDPOINT: api_endpoint,
    VERSION: "1.0.0",
    NAME: "ZebrainCustomer",
    TOKEN_NAME: 'zebrain_customer_token',
    SESSION_DATA: 'zebrain_customer_session',
    ENV: process.env.NODE_ENV,
    HOME_PAGE: home_page,
    SYSTEM: system
};
