import {IonContent, IonPage} from '@ionic/react';
import React from "react";
import {changeUrl} from "../../services/Navigation";
import Api from "../../services/Api";
import Header from "../../components/header/Header";

interface ComponentState {
  uid: string,
  token: string,
  password: string
  passwordVerify: string
  error: string
  resetSuccessful: boolean
}

interface ComponentProps {
  hasToken: boolean
}

export default class ResetPassword extends React.Component<ComponentProps, ComponentState>  {
  api = Api.getInstance()

  state = {
    uid: '',
    token: '',
    password: '',
    passwordVerify: '',
    error: '',
    resetSuccessful: false,
  }

  componentDidMount() {
    const parts = window.location.pathname.split('/')
    if (parts.length > 3) {
      this.setState({uid: parts[2], token: parts[3]})
    } else {
      this.setState({error: 'There is something wrong with the url.'})
    }
  }

  resetPassword = () => {
    const data = {
      uid: this.state.uid,
      token: this.state.token,
      password: this.state.password,
      password_verify: this.state.passwordVerify
    }
    const expectedErrors = ['password_reset_expired', 'invalid_password', 'unmatched_passwords']
    this.api.post('v1/reset_password', data, expectedErrors).then(_response => {
      this.setState({resetSuccessful: true})
    }).catch(response => {
      if (response.isExpectedError) {
        let error: string
        if (response.json.message) {
          error = response.json.message
          if (response.json.extendedData && response.json.extendedData.errors) {
            error += ": " + response.json.extendedData.errors[0]
          }
        } else {
          error = response.errorName
        }
        this.setState({error: error})
      } else {
        this.setState({error: 'Unexpected error'})
      }
    })
  }

  handlePassword = (e: any) => {
      this.setState({password: e.target.value})
  }

  handlePasswordVerify = (e: any) => {
    this.setState({passwordVerify: e.target.value})
  }

  resetDisabled = () => {
    return this.state.password.length < 4 || this.state.passwordVerify !== this.state.password
  }

  showAlreadyLoggedIn = () => {
    return (
      <div className="page_section mb-8">
        <h2>Already logged in?</h2>
        <p className="mt-4">
          The link you clicked is a one-time link to get started.
        </p>
        <p>
          Proceed to start page and bookmark that url instead.
        </p>
        <div className="button blue inline_block mt-4" onClick={() => changeUrl('/')}>
          Proceed to start page
        </div>
      </div>
    )
  }

  render () {
    if (this.state.resetSuccessful) {
      return (
        <IonPage>
          <IonContent>
            <Header name="Zebrain customer: Password stored"/>
            <div className="page_content">
              <div className="page_section">
                <p>All set!</p>
                <div className="button blue inline_block mt-4" onClick={() => changeUrl('/')}>
                  Proceed to login page
                </div>
              </div>
            </div>
          </IonContent>
        </IonPage>
      )
    }
    return (
      <IonPage>
        <IonContent>
          <Header name="Zebrain customer: Select password"/>
          <div className="page_content">
            {
              this.props.hasToken && this.showAlreadyLoggedIn()
            }
            <div className="page_section">
              <p>
                Select a password that is at least eight characters long.
              </p>
              <p className="font_small mt-4">Password</p>
              <div className="mt-2">
                <input data-private  type="password" placeholder="password" name="pw1" value={this.state.password}
                       onInput={e => this.handlePassword(e)}/>
              </div>

              <p className="font_small mt-4">Verify password</p>
              <div className="mt-2">
                <input data-private  type="password" placeholder="password again" name="pw2" value={this.state.passwordVerify}
                       onInput={e => this.handlePasswordVerify(e)}/>
              </div>

              <div className={"button blue inline_block mt-8" + (this.resetDisabled() ? " button_disabled" : "")}
                         onClick={() => this.resetPassword()}>
                Save password
              </div>

              {
                this.state.error.length > 0 &&
                  <div className="mt-8">{this.state.error}</div>
              }
            </div>
          </div>
        </IonContent>
      </IonPage>
    )
  }
}
