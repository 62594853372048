import React from "react";
import Api from "../../services/Api";
import {DepartmentInterface} from "../../services/interfacesApi";

interface ComponentProps {
  close: Function
}

interface ComponentState {
  departments: Array<DepartmentInterface>
  openFilterGroup: boolean
  saveNr: number
  error: string
}

export default class SelectDepartments extends React.Component<ComponentProps, ComponentState>  {
  api = Api.getInstance()
  min_selected_users = 5

  state = {
    departments: this.api.profile.userDepartments.departments,
    openFilterGroup: false,
    saveNr: 0,
    error: ''
  }

  changes = 0

  groups = ['Main group', 'Group B', 'Group C', 'Group D']
  groupChar = ['A', 'B', 'C', 'D']
  groupNrs = [0, 1, 2, 3]

  getSelectedDepartmentIds = () => {
    const departments: Array<Array<number>> = [[], [], [], []]
    for (const department of this.state.departments as Array<DepartmentInterface>) {
      for (let groupNr = 0; groupNr < 4; groupNr++) {
        if (department.selected[groupNr]) {
          departments[groupNr].push(department.id)
        }
      }
    }
    return departments
  }

  checkError = (departments: Array<Array<number>>) => {
    if (departments[0].length === 0) {
      return "Please select at least one department in Main Group"
    } else if (departments[1].length === 0) {
      if (departments[2].length > 0) {
        return "Group B: Can not be empty when you have selections in Group C"
      } else if (departments[3].length > 0) {
        return "Group B: Can not be empty when you have selections in Group D"
      }
    } else if (departments[2].length === 0) {
      if (departments[3].length > 0) {
        return "Group C: Can not be empty when you have selections in Group D "
      }
    }

    const selectedUsers = this.calculateSelectedUsers()
    for (let groupNr = 0; groupNr < 4; groupNr++) {
      if (selectedUsers[groupNr] < this.min_selected_users && selectedUsers[groupNr] !== 0) {
        return  this.groups[groupNr] + ": Your selection of groups need to contain at least  "
          + this.min_selected_users + " users"
      }
    }

    return ""
  }

  selectDepartments = (closeFilter: boolean) => {
    this.changes = 0
    const departments = this.getSelectedDepartmentIds()
    const error = this.checkError(departments)

    this.setState({error: error})

    if (error.length === 0) {
      const data = {
        departments: departments
      }
      this.save(data, closeFilter)
    }
  }

  reset = () => {
    const departments = this.state.departments
    for (const department of departments) {
      department.selected = [true, false, false, false]
    }
    this.changes++
    this.setState({departments: departments})
  }

  save = (data: any, closeFilter: boolean) => {
    this.api.post('v1/departments/' + this.api.profile.customer?.id, data).then(response => {
      this.api.profile.userDepartments = response.json.userDepartments
      this.api.storeProfile()
      if (closeFilter) {
        this.setState({openFilterGroup: false, saveNr: this.state.saveNr + 1})
      }
      this.setState({saveNr: this.state.saveNr + 1})
      this.props.close()
    })
  }

  selectDepartment = (department: DepartmentInterface, group: number) => {
    this.changes++
    const departments = this.state.departments
    for (const dep of departments as Array<DepartmentInterface>) {
      if (dep.id === department.id) {
        const selected = !dep.selected[group]
        dep.selected[group] = selected
        this.selectDaughters(dep.id, group, selected)
      }
    }
    this.setState({departments: departments, error: ''})
  }

  selectDaughters = (departmentId: number, group: number, selected: boolean) => {
    for (const dep of this.state.departments as Array<DepartmentInterface>) {
      if (dep.parentId === departmentId) {
        dep.selected[group] = selected
        this.selectDaughters(dep.id, group, selected)
      }
    }
  }

  toggleFilterGroup = () => {
    const openFilterGroup = !this.state.openFilterGroup
    if (openFilterGroup) {      // Opening filter groups
      this.setState({openFilterGroup: true, departments: this.api.profile.userDepartments.departments})
    } else {
      if (this.changes > 0) {
        const departments = this.getSelectedDepartmentIds()
        const error = this.checkError(departments)
        this.setState({error: error})
        if (error.length === 0) {
          // this.setState({openFilterGroup: false})
          this.selectDepartments(true)
        }
      } else {
        this.setState({openFilterGroup: false})
      }
    }
  }

  calculateSelectable = () => {
    let selectable = [true, true, false, false]
    for (const department of this.state.departments) {
      if (department.selected[1] || department.selected[2] || department.selected[3]) {
        selectable[2] = true
      }
      if (department.selected[2] || department.selected[3]) {
        selectable[3] = true
      }
    }
    return selectable
  }

  calculateSelectedUsers = () => {
    let selected = [0, 0, 0, 0]
    for (const department of this.state.departments) {
      for (let i = 0; i < 4; i++) {
        if (department.selected[i]) {
          selected[i] += department.clients
        }
      }
    }
    return selected
  }

  render () {
    const selectable = this.calculateSelectable()
    const selectedUsers = this.calculateSelectedUsers()

    return (
      <div className="flex">
        <div>
          <div className="button blue inline_block" onClick={() => this.toggleFilterGroup()}>
            Filter
            <img alt="filter" className="icon_small ml-2 align-bottom"
                 src={"/assets/icon/" + (this.state.openFilterGroup ? "cross.svg" : "filter.svg")} />
          </div>
        </div>

        {
          this.state.openFilterGroup &&
            <div className="pl-8 bg_white">
              <div className="flex">
              {
                this.groupNrs.map(groupNr => {
                  return (
                    <div className={"mr-4" + (selectable[groupNr] ? "" : " button_disabled")}
                         key={"group_col_" + groupNr}>
                      <h4 className="mb-4">{this.groups[groupNr]}</h4>
                      {
                        this.state.departments.map(department => {
                          const checked = department.selected[groupNr]
                          const depClasses = department.level > 1 ? ' font_small color_dark_gray' : ''
                          return (
                            <div className="flex items-center" key={"dep_" + groupNr + '_' + department.id}
                                 style={{
                                   marginTop: (department.level === 1 ? "25px" : "10px"),
                                   // marginLeft: (department.level * 16) + "px"
                                 }}
                                 onClick={() => this.selectDepartment(department, groupNr)}>
                              <div className={"checkbox" + (checked ? " checked": "")}/>
                              <div className={"ml-2 whitespace-nowrap" + depClasses}>
                                {department.name} {department.clients > 0 ? "(" + department.clients + ")" : ''}
                              </div>
                            </div>
                          )
                        })
                      }
                      <div className="top_margin">
                        Total users: {selectedUsers[groupNr]}
                      </div>
                    </div>
                  )
                })
              }
              </div>
              {
                this.state.error.length > 0 &&
                  <div className="mt-8 color_warn"><h4>{this.state.error}</h4></div>
              }
              <div className="mt-8 flex">
                  <div className="button green inline_block" onClick={() => this.selectDepartments(false)}>Show result</div>
                  <div className="ml-8 button white inline_block" onClick={() => this.reset()}>Clear filter</div>
              </div>
            </div>
        }
      </div>
    )
  }
}
