interface RowValueInterface {
  nr: number
  value: any
  row: any
}
export function sortTable(tableId: string, colNr: number, reverse: boolean, start_row=1, last_row: number = 0) {
  let table: HTMLTableElement|null = document.getElementById(tableId) as HTMLTableElement;

  if (!table) {
    return
  }
  let rows
  let x: any
  const rowValues: Array<RowValueInterface> = []

  rows = table.rows;

  for (let i = start_row; i < rows.length + last_row; i += 1) {
    x = rows[i].getElementsByTagName("TD")[colNr];
    const xCleaned = x.textContent.replace(/\+|%/g, '').replace(/\+|pp/g, '')
    let xVal = Number(xCleaned)
    if (isNaN(xVal)) {
      xVal = x.textContent.toLowerCase()
    }

    rowValues.push({
      nr: i,
      value: xVal,
      row: rows[i]
    })
  }

  rowValues.sort((n1,n2) => {
    if (n1.value > n2.value) {
      return reverse ? 1 : -1
    }
    if (n1.value < n2.value) {
      return reverse ? -1 : 1
    }
    return 0
  })

  const parentNode = rows[start_row].parentNode

  if (parentNode) {
    let insertBeforeEl = rows[start_row]
    for (let rowValue of rowValues.reverse()) {
      parentNode.insertBefore(rowValue.row, insertBeforeEl)
      insertBeforeEl = rowValue.row
    }
  }

  // for (let i = rows.length - 1; i >= start_row; i--) {
  //   table.deleteRow(i)
  // }
  //
  // for (const rowValue of rowValues) {
  //   table.appendChild(rowValue.row)
  //   // console.log(rowValue.value)
  // }
}

export function sortTableOld(tableId: string, colNr: number, reverse: boolean, start_row=1) {
  let table, rows, switching, i, x, y, shouldSwitch;
  table = document.getElementById(tableId);
  if (!table) {
    return
  }
  switching = true;
  /* Make a loop that will continue until
  no switching has been done: */
  while (switching) {
    // Start by saying: no switching is done:
    switching = false;
    // @ts-ignore
    rows = table.rows;
    /* Loop through all table rows (except the
    first, which contains table headers): */
    for (i = start_row; i < (rows.length - 1); i += 1) {
      // Start by saying there should be no switching:
      shouldSwitch = false;
      /* Get the two elements you want to compare,
      one from current row and one from the next: */
      x = rows[i].getElementsByTagName("TD")[colNr];
      y = rows[i + 1].getElementsByTagName("TD")[colNr];
      const xCleaned = x.textContent.replace(/\+|%/g, '').replace(/\+|pp/g, '')
      const yCleaned = y.textContent.replace(/\+|%/g, '').replace(/\+|pp/g, '')
      let xVal = Number(xCleaned)
      let yVal = Number(yCleaned)
      if (isNaN(xVal) || isNaN(yVal)) {
        xVal = x.textContent.toLowerCase()
        yVal = y.textContent.toLowerCase()
      }
      // Check if the two rows should switch place:
      if (reverse) {
        if (xVal < yVal) {
          // If so, mark as a switch and break the loop:
          shouldSwitch = true;
          break;
        }
      } else {
        if (xVal > yVal) {
          // If so, mark as a switch and break the loop:
          shouldSwitch = true;
          break;
        }
      }
    }

    if (shouldSwitch) {
      /* If a switch has been marked, make the switch
      and mark that a switch has been done: */
      for (let j = i; j < i + 1; j++) {
        rows[j].parentNode.insertBefore(rows[j + 1], rows[j]);
      }
      switching = true;
    }
  }
}

export function showPercent(from: number, to: number, showSign: boolean, fractionDigits: number, unit: string) {
  if (!from) {
    return "N/A"
  }
  const percentage = 100 * (to - from) / from

  const sign = (showSign && percentage !== 0) ?
    percentage < 0 ? '' : '+'
    : ''

  return sign + percentage.toFixed(fractionDigits) + unit
}

export function showPercentageUnits(from: number, to: number, showSign: boolean, fractionDigits: number, unit: string) {

  const percentageUnits = to - from

  const sign = (showSign && percentageUnits !== 0) ?
    percentageUnits < 0 ? '' : '+'
    : ''

  return sign + percentageUnits.toFixed(fractionDigits) + unit
}

export function showDifference(fromValue: number, toValue: number) {
  const difference = toValue - fromValue
  return difference > 0 ? '+': '' + difference.toFixed(0)
}


