import store from "../redux/store"
import {URL_CHANGE} from "../redux/actionTypes"


export function storeUrl(url: string) {
  store.dispatch({type: URL_CHANGE, payload: url})
}

export function changeUrl(url?: string) {
  if (url) {
    window.history.pushState({}, "Zebrain", url)
    storeUrl(url)
  }
}

export function popUrl(url?: string) {
  if (url) {
    // window.history.back()
    storeUrl(url)
  }
}
