import React, { useLayoutEffect, useRef } from 'react';
import * as d3 from 'd3';

function BarChart(props) {
    const chartWidth = 800;
    const chartHeight = 300;

    const svgRef = useRef();
    const tooltipRef = useRef();

    useLayoutEffect(() => {
        const series = props.groups
        const yScale = d3.scaleLinear()
            .domain([0, 100])
            .range([0, chartHeight]);

        const groups = series.length;
        const subGroups = series[0].subgroups.length;

        // Assume all serie groups has same number of data points
        const barsPerGroup = series[0].subgroups[0].values.length
        console.log('groups: ' + groups + ', subgroups ' + subGroups + ', barspergroup ' + barsPerGroup)
        // Slots is number of bars plus an empty bar slot between gorups
        let slots = (barsPerGroup * subGroups + 1) * groups;
        const minSlots = 4 * (barsPerGroup + 1)
        if (slots < minSlots) {
            slots = minSlots
        }
        const innerWidth = chartWidth - 40
        const barWidth = innerWidth / slots;
        const groupWidth = barWidth * (subGroups * barsPerGroup + 1)

        // const firstBarX = (groups === 1 && subGroups === 1) ? (barWidth / 2) + 40 : 40;
        const firstBarX = 40
        // console.log('barsPerGroup: ' + barsPerGroup + ', subgroups: ' + subGroups)
        console.log(innerWidth + ', groupWidth: ' + groupWidth + ', barWidth: ' + barWidth + ', slots ' + slots)
        let barTexts = []
        if (subGroups === 1) {
            if (barsPerGroup === 2) {
                barTexts = ['Current', 'Desired']
            } else {
                barTexts = ['Start', 'Current', 'Desired']
            }
        }

        const corner_radius = barWidth > 16 ? 8 : 4;

        const svg = d3.select(svgRef.current);
        svg.selectAll("*").remove()
        const tooltip = d3.select(tooltipRef.current);
        const g = svg.append('g')
            .attr('transform', `translate(10, 20)`);
        g.selectAll('g')
            .data([0, 25, 50, 75, 100])
            .enter()
            .append('g')
            .attr('transform', (d, i) => `translate(0, ${chartHeight - yScale(d)})`)
            .append('line')
            .attr('x1', 0)
            .attr('y1', 0)
            .attr('x2', chartWidth)
            .attr('y2', 0)
            .attr('stroke', '#e5e5e9')
            .attr('stroke-width', 1);

        g.selectAll('g')
            .append('text')
            .attr('x', 0)
            .attr('y', -10)
            .attr('text-anchor', 'start')
            .attr('alignment-baseline', 'middle')
            .attr('font-size', 12)
            .text(d => d);

        // centralize
        const g2 = g.append('g')
            .attr('transform', `translate(${firstBarX}, 0)`);

        const g_enter_group = g2.selectAll('g')
            .data(series)
            .enter()
            .append('g')
            .attr('transform', (d, i) => `translate(${i * groupWidth}, 0)`);

        if (props.showAreaName) {
            // Print group name
            g_enter_group.append('text')
                .attr('x', (groupWidth - barWidth) / 2)
                .attr('y', chartHeight + 20)
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'middle')
                .attr('font-size', 12)
                .text((d, i) => d.name);
        } else {
            // Print start?, current, desired
            for (let i = 0; i < barTexts.length; i++) {
                const translateX = barWidth * (i + 0.5);
                const barText = barTexts[i];
                g_enter_group.append('text')
                    .attr('x', 0)
                    .attr('y', 0)
                    .attr('text-anchor', 'end')
                    .attr('font-size', 12)
                    .attr('transform', `translate(${translateX},${chartHeight+16})rotate(-45)`)
                    .text(barText);
            }
        }

        const subGroup = g_enter_group.selectAll('g')
            .data(d => d.subgroups)
            .enter()
            .append('g')
            .attr('transform', (d, i) => `translate(${i * barWidth * barsPerGroup}, 0)`);

        const point_enter = subGroup.selectAll('path')
            .data(d => d.values)
            .enter();

        point_enter
            .append('path')
            .attr('d', (d, i) => generatePathData(
                i * barWidth,
                chartHeight - yScale(d.value),
                barWidth,
                yScale(d.value),
                corner_radius,
                0,
                0,
                corner_radius
            ))
            .attr('fill', d => d.color);

        if (subGroups === 1) {
            subGroup.append('line')
                .attr('x1', barsPerGroup * barWidth)
                .attr('y1', d => chartHeight - yScale(d.values[0].value))
                .attr('x2', barsPerGroup * barWidth)
                .attr('y2', d => chartHeight - yScale(d.values[1].value))
                .attr('stroke', 'black')
                .attr('stroke-dasharray', '3,2')
                .attr('stroke-width', 1);
            subGroup.append('line')
                .attr('x1', 0)
                .attr('y1', d => chartHeight - yScale(Math.max(d.values[0].value, d.values[1].value)))
                .attr('x2', barsPerGroup * barWidth)
                .attr('y2', d => chartHeight - yScale(Math.max(d.values[0].value, d.values[1].value)))
                .attr('stroke', 'black')
                .attr('stroke-dasharray', '3,2')
                .attr('stroke-width', 1);
            subGroup.append('line')
                .attr('x1', 0)
                .attr('y1', d => chartHeight - yScale(Math.min(d.values[0].value, d.values[1].value)))
                .attr('x2', barsPerGroup * barWidth)
                .attr('y2', d => chartHeight - yScale(Math.min(d.values[0].value, d.values[1].value)))
                .attr('stroke', 'black')
                .attr('stroke-dasharray', '3,2')
                .attr('stroke-width', 1);
            subGroup.append('text')
                .attr('x', barsPerGroup * barWidth + 6)
                .attr('y', d => chartHeight - yScale((d.values[0].value + d.values[1].value) / 2))
                .attr('text-anchor', 'start')
                .attr('alignment-baseline', 'middle')
                .attr('font-size', 12)
                .text(d => Math.round((d.values[1].value - d.values[0].value)) + 'pp');
        }


        tooltip
            .attr("class", "mouse_over")
            .text("");
        const set_tooltip = (event) => {
            const overW = event.target.parentNode
            const data = overW.__data__;
            let html = '<div class="mouse_arrow"></div><div class="mouse_over_inner">' + data.html + '</div>'
            tooltip.style("visibility", "visible")
                .style("top", "100px")
                .style("left", (event.pageX - 110) + 'px')
                .html(html);
        }

        subGroup
            .on("mouseover", () => tooltip.style("visibility", "visible"))
            .on("mousemove", (event) => {
                // tooltip.attr("transform", "translate(" + (event.pageX) + "," + (event.pageY - 10) + ")");
                // set_tooltip_data(event.target.parentNode.__data__);
                set_tooltip(event);
            })
            .on("mouseout", () => tooltip.style("visibility", "hidden"));


    }, [props]);

    return (
        <div className="relative">
            <div ref={tooltipRef} className="tooltip">
                <div className="mouse_arrow"/>
                <div>
                    <div className="legend"></div>
                    <div></div>
                </div>
            </div>
            <svg ref={svgRef} width={chartWidth} height={chartHeight + 100}>
            </svg>
        </div>
    )
}

export default BarChart;
// generate a path's arc data parameter
// http://www.w3.org/TR/SVG/paths.html#PathDataEllipticalArcCommands
var arcParameter = function (rx, ry, xAxisRotation, largeArcFlag, sweepFlag, x, y) {
    return [rx, ',', ry, ' ',
        xAxisRotation, ' ',
        largeArcFlag, ',',
        sweepFlag, ' ',
        x, ',', y].join('');
};

/*
 * Generate a path's data attribute
 *
 * @param {Number} width Width of the rectangular shape
 * @param {Number} height Height of the rectangular shape
 * @param {Number} tr Top border radius of the rectangular shape
 * @param {Number} br Bottom border radius of the rectangular shape
 * @return {String} a path's data attribute value
 */
var generatePathData = function (x, y, width, height, tr, br, bl, tl) {
    var data = [];

    // start point in top-middle of the rectangle
    data.push('M' + (x + width / 2) + ',' + y);

    // next we go to the right
    data.push('H' + (x + width - tr));

    if (tr > 0) {
        // now we draw the arc in the top-right corner
        data.push('A' + arcParameter(tr, tr, 0, 0, 1, (x + width), (y + tr)));
    }

    // next we go down
    data.push('V' + (y + height - br));

    if (br > 0) {
        // now we draw the arc in the lower-right corner
        data.push('A' + arcParameter(br, br, 0, 0, 1, (x + width - br), (y + height)));
    }

    // now we go to the left
    data.push('H' + (x + bl));

    if (bl > 0) {
        // now we draw the arc in the lower-left corner
        data.push('A' + arcParameter(bl, bl, 0, 0, 1, (x + 0), (y + height - bl)));
    }

    // next we go up
    data.push('V' + (y + tl));

    if (tl > 0) {
        // now we draw the arc in the top-left corner
        data.push('A' + arcParameter(tl, tl, 0, 0, 1, (x + tl), (y + 0)));
    }

    // and we close the path
    data.push('Z');

    return data.join(' ');
};

