import {IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import React from "react";
import {changeUrl} from "../../services/Navigation";

export default class NotFound extends React.Component<{}>  {

  componentDidMount() {
    console.log('Overview did mount')
  }

  componentWillUnmount() {
    console.log('Overview will unmount')
  }

  render () {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Overview</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="top_margin">
            <h1>Page not found!</h1>
          </div>
          <IonButton onClick={() => changeUrl("/")}>Start over</IonButton>
        </IonContent>
      </IonPage>
    )
  }
}
