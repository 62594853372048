import React from "react";
import {MEETING_STATUS} from "./interfaces";

export function getMeetingsBehind(licenseStart: string, licenseEnd: string, meetings_total: number,
                                       meetings_consumed: number, meetings_booked: number):number {
  if (licenseStart && licenseEnd && meetings_total > 0) {
    const start = new Date(licenseStart)
    const end = new Date(licenseEnd)
    const daysLicense = (end.getTime() - start.getTime()) / (24 * 3600 * 1000)
    // console.log(start + ' - ' + end + ' = ' + daysLicense.toFixed(1))
    const now = new Date()
    const daysPassed = (now.getTime() - start.getTime()) / (24 * 3600 * 1000)
    const period = Math.floor(daysPassed * meetings_total / daysLicense) + 1
    const behind = period - meetings_consumed - meetings_booked

    if (behind > 2) {
      return MEETING_STATUS.BEHIND
    } else if (behind > 1) {
      return MEETING_STATUS.CATCHUP
    } else {
      return MEETING_STATUS.ON_TRACK
    }
  }
  return MEETING_STATUS.NA
}

export function formatMeetingStatusNew(licenseStart: string, licenseEnd: string, meetings_total: number,
                                       meetings_consumed: number, meetings_booked: number) {


  const behind = getMeetingsBehind(licenseStart, licenseEnd, meetings_total, meetings_consumed, meetings_booked)

  if (behind === MEETING_STATUS.NA) {
    return (<>N/A</>)
  }

  let statusText = 'On track'
  let icon = 'ok_circle.svg'

  if (behind === MEETING_STATUS.BEHIND) {
    statusText = 'Behind'
    icon = 'warning_triangle.svg'
  } else if (behind === MEETING_STATUS.CATCHUP) {
    statusText = 'Catch up'
    icon = 'exclamation_circle.svg'
  }

  return (
    <div className="flex items-center">
      <img src={"/assets/icon/" + icon} alt="status" style={{width: "16px", height: "16px"}}/>
      <p className="ml-2">{statusText}</p>
    </div>
  )
}

export function formatMeetingStatus(status: string) {
  let statusText = status
  let statusColor = ''
  switch(status) {
    case 'N/A': {
      statusText = 'N/A'
      break
    }
    case 'DONE': {
      statusText = 'Done'
      statusColor = '#0c0'
      break
    }
    case 'ON_TRACK': {
      statusText = 'On track'
      statusColor = '#0c0'
      break
    }
    case 'BEHIND_ONE': {
      statusText = 'Behind'
      statusColor = '#ff0'
      break
    }
    case 'BEHIND_MANY': {
      statusText = 'Behind'
      statusColor = '#f00'
      break
    }
    case 'UNUSED_MEETINGS': {
      statusText = 'Unused meetings'
      statusColor = '#ff0'
      break
    }
    default: {
      statusText = 'Behind'
      statusColor = '#f00'
      break
    }
  }

  if (statusColor.length > 0) {
    return (
      <div className="flex items-center">
        <p>{statusText}</p>
        <div className="ml-2" style={{width: "16px", height: "16px", borderRadius: "8px", backgroundColor: statusColor}}/>
    </div>
  )
  }
  return (<>{statusText}</>)
}