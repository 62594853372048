import {IonContent, IonLabel, IonPage, IonSegment, IonSegmentButton} from '@ionic/react';
import './Heatmap.css';
import React from "react";
import Header from "../../components/header/Header";
import {BreadcrumbInterface} from "../../components/header/interfaces";
import Api from "../../services/Api";
import {AreaInterface, RangeInterface, StatsInterface} from "./interfaces";
import {sortTable, showPercent, showPercentageUnits} from "../../helpers";
import BarChart from "../../components/graphics/BarChart"
import {
  colors,
  colorsStart,
  groupName,
  areas,
  areaNrFromPos,
  colorsDesired, AreaNumberInterface
} from "./common";
import {changeUrl} from "../../services/Navigation";
import {
  BarGroupInterface,
  BarSubGroupInterface,
  BarValueInterface
} from "../../components/graphics/interfaces";
import {renderSpread, renderSpreadNegative} from "./commonFunctions";
import {showGroupChange} from "./boxes";

interface MouseOverInterface {
  name: string
  areaNr: number
  groupNr: number
}

interface ComponentProps {
  navigation: any
  stats: StatsInterface
  dataVersion: number
  reload: Function
}

interface ComponentState {
  areaNr: number
  subPage: string
  showTables: boolean
  allQuestions: Array<AreaInterface>
  mouseOver: MouseOverInterface|undefined
  filterGroups: Array<number>
  showHighlights: boolean
}

export default class HeatmapProgress extends React.Component<ComponentProps, ComponentState>  {

  api = Api.getInstance()

  state = {
    areaNr: this.props.navigation.sub2 ? parseInt(this.props.navigation.sub2) : 0,
    subPage: this.props.navigation.sub2 ? this.props.navigation.sub2 : '0',
    showTables: false,
    allQuestions: [],
    mouseOver: undefined,
    filterGroups: [0, 1, 2, 3],
    showHighlights: true
  }

  sortOrders: Array<boolean> = [true, false, false, false, false, false, false]

  summarizeData = () => {
    const allQuestions = []
    for (let area of areas) {
      if (area.nr > -1 && area.nr < 4) {
        for (let i = 0; i < area.questions; i++) {
          for (let groupNr = 0; groupNr < this.props.stats.change.length; groupNr++) {
            if (this.props.stats.change[groupNr] && this.props.stats.change[groupNr].clients > 1) {
              const question = this.props.stats.change[groupNr].areas[area.nr].questions[i]
              question.question.areaName = area.name
              question.group = groupNr
              allQuestions.push(question)
            }
          }
        }
      }
    }

    this.setState({allQuestions: allQuestions})
  }

  getExtremeValue = (question: any, sortBy: string) => {
    if (sortBy === 'change') {
      return question.current - question.start
    }
    return 0
  }

  getExtremeStatementTotal = (groupNr: number, sortBy: string, highest: boolean) => {
    let extremeQuestion = this.props.stats.change[groupNr].areas[0].questions[0]
    for (const changeArea of this.props.stats.change[groupNr].areas) {
      for (const question of changeArea.questions) {
        const thisExtreme = this.getExtremeValue(question, sortBy)
        const prevExtreme = this.getExtremeValue(extremeQuestion, sortBy)
        if (highest) {
          if (thisExtreme > prevExtreme) {
            extremeQuestion = question
          }
        } else {
          if (thisExtreme < prevExtreme) {
            extremeQuestion = question
          }
        }
      }
    }
    return extremeQuestion
  }

  getExtremeStatementAreas = (groupNr: number, sortBy: string, highest: boolean) => {
    let extremeArea = this.props.stats.change[groupNr].areas[0]
    let areaPos = 0
    let extremeAreaPos = 0
    for (const changeArea of this.props.stats.change[groupNr].areas) {
      const thisExtreme = this.getExtremeValue(changeArea.summary, sortBy)
      const prevExtreme = this.getExtremeValue(extremeArea.summary, sortBy)
      if (highest) {
        if (thisExtreme > prevExtreme) {
          extremeArea = changeArea
          extremeAreaPos = areaPos
        }
      } else {
        if (thisExtreme < prevExtreme) {
          extremeArea = changeArea
          extremeAreaPos = areaPos
        }
      }
      areaPos++
    }
    return extremeAreaPos
  }

  getExtremeStatementArea = (groupNr: number, areaNr: number, sortBy: string, highest: boolean) => {
    const changeArea = this.props.stats.change[groupNr].areas[areaNr]
    let extremeQuestion = changeArea.questions[0]
    for (const question of changeArea.questions) {
      const thisExtreme = this.getExtremeValue(question, sortBy)
      const prevExtreme = this.getExtremeValue(extremeQuestion, sortBy)
      if (highest) {
        if (thisExtreme > prevExtreme) {
          extremeQuestion = question
        }
      } else {
        if (thisExtreme < prevExtreme) {
          extremeQuestion = question
        }
      }
    }
    return extremeQuestion
  }

  getGroupNrs = () => {
    const groupNrs = []
    for (let groupNr = 0; groupNr < this.props.stats.change.length; groupNr++) {
      groupNrs.push(groupNr)
    }
    return groupNrs
  }

  componentDidMount() {
    this.summarizeData()
    this.api.get('v1/log/' + this.api.profile.customer?.id + '/' + 10).then()
  }

  componentDidUpdate(prevProps: ComponentProps, prevState: ComponentState, snapshot: any) {
    if (this.props.dataVersion !== prevProps.dataVersion) {
      this.summarizeData()
    }
    if (this.props.navigation.sub2 !== prevProps.navigation.sub2) {
      const subPage = this.props.navigation.sub2 ? this.props.navigation.sub2 : '0'
      this.setState({areaNr: parseInt(subPage), subPage: subPage})
    }
  }

  changeSubpage = (subPage: string|undefined) => {
    if (subPage) {
      this.setState({subPage: subPage})
      changeUrl('/heatmap/progress/' + subPage)
    }
  }

  showGroupColors = () => {
    return (
      <div className="top_margin">
        {
          this.props.stats.change.map((change: any, i) => {
            const clients = change ? change.clients : 0
            return (
              <div className="flex items-center mt-2" key={"group_colors_" + i}>
                <h4 style={{width: "120px"}}>{groupName[i]}</h4>
                <div style={{width: "100px"}}>{clients} users</div>
                <div>Current state:</div>
                <div className="ml-2 icon_small round" style={{backgroundColor: colors[i]}}/>
                <div className="ml-4">Desired state: </div>
                <div className="ml-2 icon_small round" style={{backgroundColor: colorsDesired[i]}}/>
              </div>
            )
          })
        }
      </div>
    )
  }

  largestChange = (questions: Array<AreaInterface>, groupNr: number) => {
    if (questions.length === 0) {
      return undefined
    }
    let largestQ:AreaInterface|undefined
    let largestChange = 0
    for (let q of questions) {
      if (q.group !== groupNr) {
        continue
      }
      let c = q.current - q.start
      if (c > largestChange) {
        largestQ = q
        largestChange = c
      }
    }
    return largestQ
  }

  sortCol = (col: number, tableId: string, startRow: number = 1) => {
    this.sortOrders[col] = !this.sortOrders[col]
    sortTable(tableId, col, this.sortOrders[col], startRow)
  }

  mouseEnter = (e: any, name: string, areaNr: number, groupNr: number) => {
    this.setState({
      mouseOver: {
        name: name,
        areaNr: areaNr,
        groupNr: groupNr
      } as MouseOverInterface
    }, () => {
      const overW = document.getElementById('mouse_over')
      if (overW) {
        const rect = e.target.getBoundingClientRect()
        const popHeight = overW.offsetHeight
        const elHeight = rect.bottom - rect.top
        const top = + elHeight / 2 - popHeight / 2
        overW.style.top = rect.top + top + 'px'
        overW.style.left = rect.right + 4 + 'px'
      }
    })
  }

  mouseLeave = () => {
    this.setState({mouseOver: undefined})
  }

  renderMouseOver = () => {
    if (this.state.mouseOver) {
      const m = this.state.mouseOver as MouseOverInterface
      if (m.name === 'departments') {
        return (
          <>
            <div className="flex mb-2 items-center">
              <div className="icon_smaller round mr-2" style={{backgroundColor: colors[m.groupNr]}}/>
              <h3>{groupName[m.groupNr]}</h3>
            </div>
            {
              this.api.profile.userDepartments.departments.filter(department => department.selected[m.groupNr])
                .map(department => {
                  return (
                    <div className="mt-1" key={"show_department_" + department.id}>
                      {department.name}
                    </div>
                  )
                })
            }
          </>
        )
      } else if (m.name === 'total_potential') {
        return (
          <p>The overall potential of all areas together</p>
        )
      } else if (m.name === 'area_change') {
        return (
          <p style={{maxWidth: "200px"}}>Area with largest difference between start state and current state</p>
        )
      } else if (m.name === 'range') {
        return (
          <p>The spread between the lowest and highest value.</p>
        )
      } else if (m.name === 'total' || m.name === 'area') {
        let start = 0
        let current = 0
        let desired = 0
        let change = 0
        let data: any
        let title = 'Missing data'
        let groupTitle = groupName[m.groupNr]
        let color = colors[m.groupNr]
        if (m.name === 'total') {
          title = 'Total potential'
          data = this.props.stats.change[m.groupNr]

        } else if (m.name === 'area') {
          const groupNrI = areaNrFromPos(m.areaNr)
          title = areas[groupNrI].name
          data = this.props.stats.change[m.groupNr].areas[m.areaNr].summary
        }
        start = data.start
        current = data.current
        desired = data.desired
        change = current - start
        return (
          <>
            <h3>{title}</h3>
            <div className="mt-2 flex items-center">
              <div className="icon_small round" style={{backgroundColor: color}}/>
              <h4 className="ml-2">{groupTitle}</h4>
            </div>
            <p className="mt-4">Start state: {start.toFixed(0)}%</p>
            <p className="mt-1">Current state: {current.toFixed(0)}%</p>
            <p className="mt-1">Desired state: {desired.toFixed(0)}%</p>
            <p className="mt-1">Progress: {change.toFixed(0)}pp (percentage units)</p>
          </>
        )
      } else {
        return (
          <>
            <h3>Missing</h3>
            <p className="mt-4">Name: {m.name}</p>
            <p className="mt-1">Group: {m.groupNr}</p>
            <p className="mt-1">Area: {m.areaNr}</p>
          </>
        )
      }
    }
  }

  toggleFilterGroups = (group: number) => {
    const remove = this.state.filterGroups.indexOf(group) > -1
    let filterGroups: Array<number>
    if (remove) {
      filterGroups = []
      for (const g of this.state.filterGroups) {
        if (g !== group) {
          filterGroups.push(g)
        }
      }
    } else {
      filterGroups = this.state.filterGroups
      filterGroups.push(group)
    }
    this.setState({filterGroups: filterGroups})
  }

  renderTable = () => {
    const groupNrs = this.getGroupNrs()
    const showGroups = this.props.stats.change.length > 1
    let addColNr = 0
    const showArea = this.state.areaNr === 0 || this.state.areaNr > 4
    let areaColNr = 0
    if (showGroups) {
      addColNr++
      areaColNr = 1
    }
    if (showArea) {
      addColNr++
    }

    let questionsFiltered = this.state.allQuestions
    if (this.state.areaNr > 0 && this.state.areaNr < 5) {
      const area = areas[this.state.areaNr]
      const areaNr = area.nr + 1
      questionsFiltered = questionsFiltered.filter((question: AreaInterface) => (question.question.areaNr === areaNr))
    }
    if (this.state.filterGroups.length !== 4) {
      questionsFiltered = questionsFiltered.filter((question: AreaInterface) => (this.state.filterGroups.indexOf(question.group) > -1))
    }

    return (
      <>
        <div className="page_section top_margin">
          <h2>Per statement</h2>
          {
            this.props.stats.change.length > 1 &&
              <>
                  <h4 className="top_margin">Filter by group</h4>
                  <div className="mt-4 flex">
                    {
                      groupNrs.map(group => {
                        return (
                          <div className="mr-4 flex" key={"filter_group_" + group} onClick={() => this.toggleFilterGroups(group)}>
                            <div className={"checkbox" + (this.state.filterGroups.indexOf(group) > -1 ? " checked" : "")}/>
                            <div className="ml-2">{groupName[group]}</div>
                          </div>
                        )
                      })
                    }
                  </div>
              </>
          }

          <table className="data_table lines mt-8" id="t">
            <colgroup>
              {showGroups && <col/>}
              {showArea && <col/>}
              <col/>
              <col/>
              <col style={{backgroundColor: "#f6faef"}}/>
              <col/>
              <col style={{backgroundColor: "#f6faef"}}/>
              <col/>
              <col style={{backgroundColor: "#f6faef"}}/>
            </colgroup>
            <thead>
            <tr className="align-top">
              {
                showGroups &&
                  <th onClick={() => this.sortCol(0, "t")} className="cursor-pointer underline">Group</th>
              }
              {
                showArea &&
                  <th onClick={() => this.sortCol(areaColNr, "t")} className="cursor-pointer underline">Area</th>
              }

              <th onClick={() => this.sortCol(addColNr, "t")} className="cursor-pointer underline">Statement</th>
              <th onClick={() => this.sortCol(addColNr + 1, "t")} className="cursor-pointer underline">Start</th>
              <th onClick={() => this.sortCol(addColNr + 2, "t")} className="cursor-pointer underline">Current</th>
              <th onClick={() => this.sortCol(addColNr + 3, "t")} className="cursor-pointer underline">Desired</th>
              <td onClick={() => this.sortCol(addColNr + 4, "t")} className="cursor-pointer underline text_center">
                <p className="bold">Progress</p>
                <p className="font_smaller whitespace-nowrap">Percentage points</p>
              </td>
              <td onClick={() => this.sortCol(addColNr + 5, "t")} className="cursor-pointer underline text_center">
                <p className="bold">Progress</p>
                <p className="font_smaller">Percent</p>
              </td>
            </tr>
            </thead>
            <tbody>
            {
              questionsFiltered.map((question: AreaInterface, index) => {
                return (
                  <tr key={"total_question_" + index + "_" + index}>
                    {
                      showGroups &&
                        <td className="whitespace-nowrap">{groupName[question.group]}</td>
                    }
                    {
                      showArea &&
                        <td className="whitespace-nowrap">{question.question.areaName}</td>
                    }
                    <td>{question.question.question}</td>
                    <td>{question.start.toFixed(1)}</td>
                    <td>{question.current.toFixed(1)}</td>
                    <td>{question.desired.toFixed(1)}</td>
                    <td>{showPercentageUnits(question.start, question.current, true, 1, '')}</td>
                    <td>{showPercent(question.start, question.current, true, 1, '')}</td>
                  </tr>
                )
              })
            }
            </tbody>
          </table>
        </div>
      </>
    )
  }

  showUsers = () => {
    const width = "187.5px"
    return (
      <div className="flex" style={{width: "800px", padding: "0 0 0 50px"}}>
        {
          this.props.stats.change.map((change, i) => {
            return (
              <div key={"group_users_" + i} style={{width: width}}>
                <div className="flex cursor-pointer items-center w-fit"
                     onMouseEnter={(e) => this.mouseEnter(e, 'departments', this.state.areaNr, i)}
                     onMouseLeave={() => this.mouseLeave()}>
                  <div className="icon_smaller round pointer-events-none" style={{backgroundColor: colors[i]}}/>
                  <h4 className="ml-2">{groupName[i]}</h4>
                </div>
                <div className="font_small" style={{marginLeft: "20px"}}>{change.clients} users</div>
              </div>
            )
          })
        }
      </div>
    )

  }

  showInfoBoxes = () => {
    const groupNrs = this.getGroupNrs()
    return (
      <div className="ml-8">
        <div className='box_gray top_margin heatmap_info_width'>
          <h4>About progress</h4>
          <p className="mt-2 font_small">
            A group's progress is defined by the difference between their first measurement (start state) and their
            last measurement (current state). The desired states shown are from their latest measurement.
          </p>
          <p className="mt-4 font_small">
            Users with only one measurement are not represented in the result.
          </p>
        </div>

        <div className='box_gray top_margin'>
          <h4>Percentage points (pp)</h4>
          <p className='mt-4 font_small'>
            Percentage points describes the difference between two percentages.
          </p>
          <p className="mt-4 font_small">
            Example: Between 20% and 25% there is a difference of 5 percentage points but 25 percent.
          </p>
        </div>

        <div className='box_gray top_margin'>
          <h3>Number of measurements</h3>
          {
            groupNrs.map(groupNr => {
              return (
                <div key={"per_measure_" + groupNr}>
                  <div className="flex items-center mt-4">
                    <div className="icon_smaller round" style={{backgroundColor: colors[groupNr]}}/>
                    <h4 className="ml-2">{groupName[groupNr]}</h4>
                  </div>

                  <div className="mt-2 font_small">
                    {this.props.stats.change[groupNr].clients} users have taken more than one measurement
                  </div>
                  {/*{*/}
                  {/*  this.props.stats.clientsPerMeasureList[groupNr].map((perMeasure, index) => {*/}
                  {/*    return (*/}
                  {/*      <div className="font_small" key={"per_measure_" + groupNr + "_" + index}>*/}
                  {/*        {perMeasure[1]} measurement{perMeasure[1] > 1 ? "s" : ""}:&nbsp;*/}
                  {/*        {perMeasure[0]} user{perMeasure[0] > 1 ? "s" : ""}*/}
                  {/*      </div>*/}
                  {/*    )*/}
                  {/*  })*/}
                  {/*}*/}
                </div>
              )
            })
          }
        </div>

      </div>
    )
  }

  showExtremesTotal = () => {

    const groupNrs = this.getGroupNrs()

    return (
      <>
        <h3 className="mt-8">Statement with largest progress</h3>
        <div className="flex flex-wrap">
          {
            groupNrs.map(groupNr => {
              const q = this.getExtremeStatementTotal(groupNr,'change', true)
              const ranges: Array<RangeInterface> = [
                {
                  name: 'Range within progress',
                  min: q.changeMin,
                  max: q.changeMax,
                  unit: 'pp',
                  color: colors[groupNr]
                },
              ]
              return (
                <div key={"largest_change_" + groupNr} className="heatmap_col_2 mr-8">
                  {showGroupChange(groupNr, q.start, q.current, this.mouseEnter, this.mouseLeave, true,
                    ranges, q.question.question, q.question.areaName)}
                </div>
              )
            })
          }
        </div>

        <h3 className="mt-16">Statement with lowest progress</h3>
        <div className="flex flex-wrap">
          {
            groupNrs.map(groupNr => {
              const q = this.getExtremeStatementTotal(groupNr, 'change', false)
              const ranges: Array<RangeInterface> = [
                {
                  name: 'Range within progress',
                  min: q.changeMin,
                  max: q.changeMax,
                  unit: 'pp',
                  color: colors[groupNr]
                },
              ]
              return (
                <div key={"largest_change_" + groupNr} className="heatmap_col_2 mr-8">
                  {showGroupChange(groupNr, q.start, q.current, this.mouseEnter, this.mouseLeave, true,
                     ranges, q.question.question, q.question.areaName)}
                </div>
              )
            })
          }
        </div>
      </>
    )
  }

  showAreasChange = () => {

    const groupNrs = this.getGroupNrs()

    return (
      <>
        <div className="mt-10 flex items-center">
          <h3>Area with largest progress</h3>
          <img alt='help' className="ml-2 icon_h2" src="/assets/icon/question_mark.svg"
               onMouseEnter={(e) =>
                 this.mouseEnter(e, 'area_change',0, 0)}
               onMouseLeave={this.mouseLeave}
          />
        </div>

        <div className="flex flex-wrap">
          {
            groupNrs.map(groupNr => {
              const areaPos = this.getExtremeStatementAreas(groupNr,'change', true)
              const areaNr = areaNrFromPos(areaPos)
              const area = areas[areaNr]
              const data = this.props.stats.change[groupNr].areas[areaPos].summary
              const ranges: Array<RangeInterface> = [
                {
                  name: 'Range within progress',
                  min: data.changeMin,
                  max: data.changeMax,
                  unit: 'pp',
                  color: colors[groupNr]
                },
              ]
              return (
                <div key={"largest_change_" + groupNr} className="heatmap_col_2 mr-8">
                  {showGroupChange(groupNr, data.start, data.current, this.mouseEnter, this.mouseLeave, true,
                    ranges, "", area.name)}
                </div>
              )
            })
          }
        </div>

        <h3 className="mt-16">Area with lowest progress</h3>
        <div className="flex flex-wrap">
          {
            groupNrs.map(groupNr => {
              const areaPos = this.getExtremeStatementAreas(groupNr,'change', false)
              const areaNr = areaNrFromPos(areaPos)
              const area = areas[areaNr]
              const data = this.props.stats.change[groupNr].areas[areaPos].summary
              const ranges: Array<RangeInterface> = [
                {
                  name: 'Range within progress',
                  min: data.changeMin,
                  max: data.changeMax,
                  unit: 'pp',
                  color: colors[groupNr]
                },
              ]
              return (
                <div key={"largest_change_" + groupNr} className="heatmap_col_2 mr-8">
                  {showGroupChange(groupNr, data.start, data.current, this.mouseEnter, this.mouseLeave, true,
                    ranges, "", area.name)}
                </div>
              )
            })
          }
        </div>
      </>
    )
  }

  showExtremesArea = () => {

    const groupNrs = this.getGroupNrs()
    const area = areas[this.state.areaNr]

    return (
      <>
        <h3 className="mt-8">Statement with largest progress</h3>
        <div className="flex flex-wrap">
          {
            groupNrs.map(groupNr => {
              const q = this.getExtremeStatementArea(groupNr, area.nr, 'change', true)
              const ranges: Array<RangeInterface> = [
                {
                  name: 'Range within progress',
                  min: q.changeMin,
                  max: q.changeMax,
                  unit: 'pp',
                  color: colors[groupNr]
                },
              ]
              return (
                <div key={"largest_change_" + groupNr} className="heatmap_col_2 mr-8">
                  {showGroupChange(groupNr, q.start, q.current, this.mouseEnter, this.mouseLeave, true,
                    ranges, q.question.question)}
                </div>
              )
            })
          }
        </div>

        <h3 className="mt-16">Statement with lowest progress</h3>
        <div className="flex flex-wrap">
          {
            groupNrs.map(groupNr => {
              const q = this.getExtremeStatementArea(groupNr, area.nr, 'change', false)
              const ranges: Array<RangeInterface> = [
                {
                  name: 'Range within progress',
                  min: q.changeMin,
                  max: q.changeMax,
                  unit: 'pp',
                  color: colors[groupNr]
                },
              ]
              return (
                <div key={"largest_change_" + groupNr} className="heatmap_col_2 mr-8">
                  {showGroupChange(groupNr, q.start, q.current, this.mouseEnter, this.mouseLeave, true,
                    ranges, q.question.question)}
                </div>
              )
            })
          }
        </div>
      </>
    )
  }

  generateHtml = (name: string, start: number, current: number, desired: number, color: string) => {
    let percentage = "-"
    if (start) {
      percentage = (100 * (current - start) / start).toFixed(0) + '%'
    }
    return `<div class="flex items-center">
              <div class="icon_small round" style="background-color: ${color}"></div>
              <h4 class="ml-2">${name}</h4>
            </div>
            <p class="mt-4">Start state: <span class="bold">${start.toFixed(0)}%</span></p>
            <p class="mt-1">Current state: <span class="bold">${current.toFixed(0)}%</span></p>
            <p class="mt-1">Desired state: <span class="bold">${desired.toFixed(0)}%</span></p>
            <p class="mt-1 whitespace-nowrap">Progress: <span class="bold">${(current - start).toFixed(0)}pp</span> 
               (percentage points)</p>
            <p class="mt-1">Progress: <span class="bold">${percentage}</span></p>`
  }

  showBarCharts = () => {
    const barGroups: Array<BarGroupInterface> = []
    if (this.state.areaNr === 5) {
      for (const area of areas) {
        if (area.nr >= 4) {
          break
        }

        const subGroups: Array<BarSubGroupInterface> = []

        let groupNr = 0
        for (const change of this.props.stats.change) {

          if (change) {
            let data: any
            if (area.nr === -1) {
              data = change
            } else {
              data = change.areas[area.nr].summary
            }

            const values: Array<BarValueInterface> = []

            values.push({
              value: data.start,
              color: colorsStart[groupNr]
            })
            values.push({
              value: data.current,
              color: colors[groupNr]
            })
            values.push({
              value: data.desired,
              color: colorsDesired[groupNr]
            })

            subGroups.push({
              values: values,
              infoName: area.nr === -1 ? 'total': 'area',
              groupNr: groupNr,
              areaNr: area.nr,
              html: this.generateHtml(groupName[groupNr], data.start, data.current, data.desired, colors[groupNr])
            })
          }

          groupNr++
        }

        const barGroup: BarGroupInterface = {
          name: area.name,
          subgroups: subGroups
        }
        barGroups.push(barGroup)
      }
      return <BarChart groups={barGroups} showAreaName={true}/>
    } else {
      const area = areas[this.state.areaNr]

      let groupNr = 0
      for (const change of this.props.stats.change) {

        const values: Array<BarValueInterface> = []
        if (change) {
          let data: any
          if (area.nr === -1) {
            data = change
          } else {
            data = change.areas[area.nr].summary
          }

          values.push({
            value: data.start,
            color: colorsStart[groupNr]
          })
          values.push({
            value: data.current,
            color: colors[groupNr]
          })
          values.push({
            value: data.desired,
            color: colorsDesired[groupNr]
          })

          const barGroup: BarGroupInterface = {
            name: groupName[groupNr],
            subgroups: [
              {
                values: values,
                infoName: area.nr === -1 ? 'total': 'area',
                groupNr: groupNr,
                areaNr: area.nr,
                html: this.generateHtml(groupName[groupNr], data.start, data.current, data.desired, colors[groupNr])
              }
            ]
          }
          barGroups.push(barGroup)
        }
        groupNr++
      }

      // return <BarDiagram groups={barGroups} mouseEnter={this.mouseEnter} mouseLeave={this.mouseLeave}/>
      return <BarChart groups={barGroups}/>
    }
  }

  showSpread = () => {

    return (
      <div className="mt-24 heatmap_col_2">
        <div className="flex items-center">
          <h3>Range</h3>
          <img alt='help' className="ml-2 icon_h2" src="/assets/icon/question_mark.svg"
               onMouseEnter={(e) =>
                 this.mouseEnter(e, 'range', 0, 0)}
               onMouseLeave={this.mouseLeave}
          />
        </div>

        <div className="flex top_margin">
          <div className="heatmap_col_2_small"><h4>Progress</h4></div>
        </div>
        {
          this.props.stats.change.map((change, groupNr) => {

            let data: any

            if (this.state.areaNr > 0 && this.state.areaNr < 5) {
              const area = areas[this.state.areaNr]
              data = change.areas[area.nr].summary
            } else {
              data = change
            }
            const changeMin = data.changeMin
            const changeMax = data.changeMax

            return (
              <div className={groupNr === 0 ? "mt-4" : "mt-12"} key={"range_spread_" + groupNr} >
                <div className="flex items-center">
                  <div className="icon_smaller round" style={{backgroundColor: colors[groupNr]}}/>
                  <p className="ml-2 font_small">{groupName[groupNr]}</p>
                </div>
                <div className="mt-2" key={"range_spread_" + groupNr}>
                  {
                    changeMin < 0 ? renderSpreadNegative(changeMin, changeMax, colors[groupNr], 'pp') :
                      renderSpread(changeMin, changeMax, colors[groupNr], 'pp')
                  }
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }

  showChangeTables = () => {

    return (
      <>
        <div className="bg_lighter_gray p-2 cursor-pointer"
             onClick={() => this.setState({showTables: !this.state.showTables})}>
          <div className="flex items-center justify-center">
            <p className="font_small">Show table</p>
            <img alt='toggle' className="icon_small ml-4"
                 src={"/assets/icon/arrow_" + (this.state.showTables ? "up" : "down") + ".svg"}/>
          </div>

        </div>
        {
          this.state.showTables &&
            <>
              {areas.filter(area => area.nr < 4).map(area => {
                return this.showChangeTable(area)
              })}
            </>
        }
      </>
    )
  }

  showChangeTable = (area: AreaNumberInterface) => {

    const groupNrs = this.getGroupNrs()

    return (
      <table className="mt-8 heatmap_table_lines wide">
        <tbody>
        <tr className="border_bottom_gray">
          <td style={{width: "180px"}}><h4>{area.name}</h4></td>
          {
            groupNrs.map(group => {
              return (
                <td key={"p_t_name_" + group + "_" + area.nr}>
                  <div className="flex items-center"
                       onMouseEnter={(e) => this.mouseEnter(e, 'departments', area.nr, group)}
                       onMouseLeave={() => this.mouseLeave()}>
                    <div className="icon_smaller round" style={{backgroundColor: colors[group]}}/>
                    <h4 className="ml-1">{groupName[group]}</h4>
                  </div>
                </td>
              )
            })
          }
        </tr>

        <tr className="border_bottom_gray font_small">
          <td>Start (%)</td>
          {
            this.props.stats.change.map((change, i) => {
              let value = "-"
              if (change) {
                const data = area.nr === -1 ? change : change.areas[area.nr].summary
                value = data.start.toFixed(0)
              }
              return (
                <td key={"p_t_start_" + i}>{value}</td>
              )
            })
          }
        </tr>

        <tr className="border_bottom_gray font_small">
          <td>Current (%)</td>
          {
            this.props.stats.change.map((change, i) => {
              let value = "-"
              if (change) {
                const data = area.nr === -1 ? change : change.areas[area.nr].summary
                value = data.current.toFixed(0)
              }
              return (
                <td key={"p_t_current_" + i}>{value}</td>
              )
            })
          }
        </tr>

        <tr className="border_bottom_gray font_small">
          <td>Desired (%)</td>
          {
            this.props.stats.change.map((change, i) => {
              let value = "-"
              if (change) {
                const data = area.nr === -1 ? change : change.areas[area.nr].summary
                value = data.desired.toFixed(0)
              }
              return (
                <td key={"p_t_desired_" + i}>{value}</td>
              )
            })
          }
        </tr>

        <tr className="border_bottom_gray font_small align-top">
          <td>
            <p className="font_small">Progress (pp)</p>
            <p className="font_smaller color_dark_gray">Percentage points</p>
          </td>
          {
            this.props.stats.change.map((change, i) => {
              let value = "-"
              if (change) {
                const data = area.nr === -1 ? change : change.areas[area.nr].summary
                const changepp = data.current - data.start
                value = changepp.toFixed(0)
              }
              return (
                <td key={"p_t_change_" + i}>{value}</td>
              )
            })
          }
        </tr>

        <tr className="border_bottom_gray font_small">
          <td>
            <p className="font_small">Progress (%)</p>
          </td>
          {
            this.props.stats.change.map((change, i) => {
              let value = "-"
              if (change) {
                const data = area.nr === -1 ? change : change.areas[area.nr].summary
                if (data.start) {
                  const changepp = data.current - data.start
                  value = (100 * (changepp / data.start)).toFixed(0)
                }

              }
              return (
                <td key={"p_t_change_" + i}>{value}</td>
              )
            })
          }
        </tr>
        </tbody>
      </table>
    )
  }

  getTooFewGroups = () => {
    const groups: Array<string> = []
    for (let i = 0; i < this.props.stats.change.length; i++) {
      if (this.props.stats.change[i].clients < 2) {
        groups.push(groupName[i])
      }
    }
    return groups
  }

  render () {
    const breadcrumbs: Array<BreadcrumbInterface> = [
      {
        name: 'Overview',
        link: '/'
      },
      {
        name: 'Heatmap',
        link: '/heatmap'
      }
    ]
    let name = "Total potential"
    let breadCrumbName = "Progress"
    const displayArea = (this.state.areaNr > 0 && this.state.areaNr < 5)
    if (this.state.areaNr > 0) {
      breadcrumbs.push({
        name: 'Progress',
        link: '/heatmap/progress'
      })
      name = breadCrumbName = areas[this.state.areaNr].name
    }

    const tooFewGroups: Array<string> = this.getTooFewGroups()

    return (
      <IonPage>
        <IonContent>
          <Header name={breadCrumbName} breadcrumbs={breadcrumbs} reload={this.props.reload}/>
          {
            this.state.mouseOver &&
              <div id="mouse_over">
                  <div className="mouse_arrow"/>
                  <div className="p-4 bg_white border_radius">
                    {this.renderMouseOver()}
                  </div>
              </div>
          }
          <div className="page_content">
            <div className="page_section">
              <h1>Progress</h1>

              <div className="page_bar mt-2"/>
              <div className="flex justify-between">
                <div>
                  <IonSegment className="light page_intro_text top_margin" value={this.state.subPage} mode="ios"
                              onIonChange={(e) => this.changeSubpage(e.detail.value)}>
                    <IonSegmentButton value="0">
                      <IonLabel>Total potential</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="1">
                      <IonLabel>Self insight</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="2">
                      <IonLabel>Basic energy</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="3">
                      <IonLabel>Motivation</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="4">
                      <IonLabel>Self leadership</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="5">
                      <IonLabel>Compare all</IonLabel>
                    </IonSegmentButton>
                  </IonSegment>

                  <div className="mt-10 flex items-center">
                    <h2>{name}</h2>
                    {
                      this.state.areaNr === 0 &&
                        <img alt='help' className="ml-2 icon_h2" src="/assets/icon/question_mark.svg"
                             onMouseEnter={(e) =>
                               this.mouseEnter(e, 'total_potential', this.state.areaNr, 0)}
                             onMouseLeave={this.mouseLeave}
                        />
                    }
                  </div>

                  {
                    tooFewGroups.length > 0 ? (
                      <div className="mt-16 box_gray">
                        <h2>Cannot show progress data</h2>
                        <p className="mt-4">
                        There are too few users with at least two measures in&nbsp;
                        {
                          tooFewGroups.map((name, i) => {
                            const separator = i === (tooFewGroups.length  - 2) ? ' and '
                              : i < (tooFewGroups.length - 1) ? ', ': ''
                            return (
                              <span key={"too_feww_" + i}>
                                {name}{separator}
                              </span>
                            )
                          })
                        }.
                        </p>
                      </div>
                    ) : (
                      <>
                        <div className="mt-10">
                          {this.showUsers()}
                        </div>

                        <div className="top_margin">
                          {this.showBarCharts()}
                        </div>

                        <div className="mt-8 page_intro_text">
                          {
                            this.state.areaNr < 5 ? this.showChangeTable(areas[this.state.areaNr]) :
                              this.showChangeTables()
                          }
                        </div>
                        {
                          this.showSpread()
                        }

                        <h2 className="mt-24">Highlights</h2>

                        <p className="mt-4 cursor-pointer" onClick={() => this.setState({showHighlights: !this.state.showHighlights})}>
                          {
                            this.state.showHighlights ? 'Hide highlights' : 'Show highlights'
                          }
                          <img className="icon_small align-middle ml-4"
                               src={"/assets/icon/arrow_" + (this.state.showHighlights ? "down" : "up") + ".svg"}/>
                        </p>

                        {
                          this.state.showHighlights &&
                          <>
                            {
                              this.state.areaNr === 5 && this.showAreasChange()
                            }
                            {
                              displayArea ? this.showExtremesArea() : this.showExtremesTotal()
                            }
                          </>
                        }
                      </>
                    )
                  }
                </div>

                {this.showInfoBoxes()}
              </div>
            </div>

            {
              tooFewGroups.length === 0 && this.renderTable()
            }
          </div>
        </IonContent>
      </IonPage>
    )
  }
}
