import {URL_CHANGE} from '../actionTypes'
const initialState = {
  url: '',
  page: '',
  sub1: '',
  sub2: '',
  sub3: '',
}

export default function parseUrl(state = initialState, action: any) {
  switch (action.type) {
    case URL_CHANGE: {
      const urlParts = action.payload.split('/')
      let page = ''
      let sub1 = ''
      let sub2 = ''
      let sub3 = ''

      if (urlParts.length > 1) {
        page = urlParts[1]
        if (urlParts.length > 2) {
          sub1 = urlParts[2]
          if (urlParts.length > 3) {
            sub2 = urlParts[3]
            if (urlParts.length > 4) {
              sub3 = urlParts[4]
              if (urlParts.length > 6) {
                sub3 = urlParts[6]
              }
            }
          }
        }
      }
      return {
        url: action.payload,
        page: page,
        sub1: sub1,
        sub2: sub2,
        sub3: sub3,
      }
    }
    default:
      return state
  }
}
