import React from "react";
import { setupIonicReact, IonApp } from '@ionic/react';
import { connect, ConnectedProps } from 'react-redux';
import { tokenStore, tokenRemove, tokenChecked, selectCustomer } from './redux/actions';
import Heatmap from './pages/heatmap/Heatmap';
import Login from "./pages/login/Login";
import NotFound from "./pages/notfound/NotFound";
import {storeUrl} from "./services/Navigation";
import {CONFIG} from "./constants";
import Overview from "./pages/overview/Overview";
import LogRocket from 'logrocket';
import '@ionic/react/css/core.css';
import './theme/variables.css';
import './theme/custom.css';
import './theme/tailwind.css';
import './theme/fonts.css';
import SelectCustomer from "./pages/customer/SelectCustomer";
import Api from "./services/Api";
import ResetPassword from "./pages/reset_password/ResetPassword";
import Clients from "./pages/clients/Clients";
import Client from "./pages/clients/Client";
import Esg from "./pages/esg/Esg"
import CreateSession from "./pages/create_session/CreateSession";


const mapState = (state: any) => ({
  session: state.session,
  navigation: state.navigation,
  customer: state.customer
});

const mapDispatch = {
  tokenStore,
  tokenRemove,
  tokenChecked,
  selectCustomer,
};

const connector = connect(mapState, mapDispatch);

interface ComponentState {}

type PropsFromRedux = ConnectedProps<typeof connector>;

setupIonicReact({
  mode: 'md',
});

class App extends React.Component<PropsFromRedux, ComponentState> {

  api = Api.getInstance()

  componentDidMount() {
    storeUrl(window.location.pathname);
    this.checkToken();
    LogRocket.init('aygh1f/customer-app-cqclv', {
      network: {
        isEnabled: false,
      },
      dom: {
        inputSanitizer: true,
      },
    });
    window.addEventListener('popstate', this.backListener)
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.backListener)
  }

  checkToken = () => {
    const token = window.localStorage[CONFIG.TOKEN_NAME];
    if (token) {
      this.props.tokenStore(token);
    } else {
      this.props.tokenChecked();
    }
    if (this.api.profile.customer) {
      this.props.selectCustomer();
    }
  }

  backListener = (event: PopStateEvent)  => {
    if (event.currentTarget) {
      // @ts-ignore
      storeUrl(event.currentTarget.location.pathname)
    }
  }

  localRouting = () => {
    if (!this.props.session.checked) {
      return <div />;
    } else if (this.props.navigation.page === 'create_session') {
      return <CreateSession />
    } else if (this.props.navigation.page === 'reset_password') {
      return <ResetPassword hasToken={this.props.session.valid}/>
    } else if (!this.props.session.valid) {
      return <Login/>;
    } else if (!this.props.customer.selectedCustomer) {
      return <SelectCustomer />;
    }

    if (this.props.navigation.page === '') {
      return (<Overview/>)
    } else if (this.props.navigation.page === 'heatmap') {
      return (<Heatmap/>)
    } else if (this.props.navigation.page === 'esg') {
      return (<Esg/>)
    } else if (this.props.navigation.page === 'users') {
      return (<Clients/>)
    } else if (this.props.navigation.page === 'user') {
      return (<Client/>)
    } else {
      return (<NotFound/>)
    }
  }

  render() {

    return (
      <IonApp>
        {this.localRouting()}
      </IonApp>
    )
  }
}

export default connector(App);
