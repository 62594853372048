export enum MEETING_STATUS {
  NA,
  ON_TRACK,
  CATCHUP,
  BEHIND
}

export interface CoachRequestInterface {
  status: number,
  createdAt: string
}

export interface ClientInterface {
  id: number
  meetings_total: number
  meetings_consumed: number
  meetings_booked_period: number
  meetings_booked_total: number
  email: string
  firstName: string
  lastName: string
  createdAt: string
  licenseName: string
  licenseStart: string
  licenseEnd: string
  hasCoach: boolean
  hasActiveLicense: boolean
  invitedToCoach: string
  status: string
  coachRequestStatus: CoachRequestInterface
}