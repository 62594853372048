import {CUSTOMER_SELECTED, CUSTOMER_REMOVED} from '../actionTypes'
const initialState = {
  selectedCustomer: false
}

export default function handleCustomer(state = initialState, action: any) {
  switch (action.type) {
    case CUSTOMER_SELECTED: {
      return {
        selectedCustomer: true
      }
    }
    case CUSTOMER_REMOVED: {
      return {
        selectedCustomer: false
      }
    }
    default:
      return state
  }
}
