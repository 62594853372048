import {IonContent, IonPage} from '@ionic/react';
import React from "react";
import Api from "../../services/Api";
import Header from "../../components/header/Header";

interface ComponentState {
  username: string
  password: string
  error: string
  forgotPassword: boolean
}

export default class Login extends React.Component<{}, ComponentState>  {
  api = Api.getInstance()

  state = {
    username: '',
    password: '',
    error: '',
    forgotPassword: false
  }

  login = () => {
    const data = {
      username: this.state.username,
      password: this.state.password
    }
    this.api.post('v1/login', data, ['login_failed']).then(response => {
      this.api.logIn(response.json.token, response.json.user, response.json.customers)
    }).catch(response => {
      if (response.isExpectedError) {
        this.setState({error: this.api.trErr(response.errorName)})
      }
    })
  }

  forgotPassword = () => {
    if (this.state.username.length < 5 || this.state.username.indexOf('@') < 2) {
      this.setState({error: 'The email address does not look right'})
      return
    }
    const data = {
      email: this.state.username,
    }
    this.api.post('v1/forgot_password', data).then(response => {
      this.setState({error: 'We have sent a mail to ' + this.state.username + ' with instructions'})
    })
  }

  handleUsername = (e: any) => {
      this.setState({username: e.target.value, error: ''})
  }

  handlePassword = (e: any) => {
      this.setState({password: e.target.value})
  }

  forgotDisabled = () => {
    if (this.state.username.length < 6) {
      return true
    }
    return false
  }

  render () {
    return (
      <IonPage>
        <IonContent>
          <Header name="Zebrain customer log in"/>
          <div className="page_content">
            <div className="page_section">
              <div>
                  <p className="font_small">Email address</p>
                  <input data-private type="text" placeholder="Email address" name="username" value={this.state.username}
                         onInput={e => this.handleUsername(e)}/>
              </div>
              {
                this.state.forgotPassword ? (
                  <>
                    <div className="mt-4 button inline_block"
                               onClick={() => this.forgotPassword()}>
                      Send mail with instructions
                    </div>
                    <div className="mt-4 underline cursor-pointer"
                         onClick={() => this.setState({forgotPassword: false})}>
                      Back to login page
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mt-4">
                      <p className="font_small">Password</p>
                      <input data-private type="password" placeholder="" name="password" value={this.state.password}
                             onInput={e => this.handlePassword(e)}/>
                    </div>

                    <div className="button inline_block mt-4" onClick={() => this.login()}>
                      Log in
                    </div>
                    <div className="mt-4 underline cursor-pointer"
                         onClick={() => this.setState({forgotPassword: true})}>
                      I have forgotten my password
                    </div>
                  </>
                )
              }

              {
                this.state.error.length > 0 &&
                  <div className="mt-8">{this.state.error}</div>
              }
            </div>
          </div>
        </IonContent>
      </IonPage>
    )
  }
}
