import React from "react";
import {colors, colorsDesired, colorsStart, getArrow, groupName} from "./common";
import {circleValue} from "./CircleValue";
import {showPercent, showPercentageUnits} from "../../helpers";
import {RangeInterface} from "./interfaces";
import {renderSpread, renderSpreadNegative} from "./commonFunctions";


export const showGroupValue = (i: number, current: number, desired: number, mouseEnter: Function, mouseLeave: Function,
                               ranges: Array<RangeInterface>, statement?: string, areaName?: string) => {

  return (
    <div key={"customer_summary_" + i} className="border_gray border_radius p-4 mt-8 flex flex-col justify-between"
         style={{height: "calc(100% - 64px)"}}>
      <div>
      {
        areaName &&
          <div className="color_dark_gray font_small">{areaName}</div>
      }
      {
        statement &&
          <h3 className="mt-1">
              &quot;{statement}&quot;
          </h3>
      }
      </div>

      <div>
        <div className="mt-10">
          <div className="mt-2 flex">
            <div className="mr-12">
              <p className="font_small bold mb-2">Current state</p>
              <div style={{width: "80px"}}>
                {circleValue(current, colors[i])}
              </div>
            </div>

            <div className="ml-12">
              <p className="font_small bold mb-2">Desired state</p>
              <div style={{width: "80px"}}>
                {circleValue(desired, colorsDesired[i])}
              </div>
            </div>
          </div>
        </div>
        {
          ranges.map((range, nr) => {
            return (
              <div className="mt-10" key={"range_" + i + "_" + nr}>
                <p className="font_small bold mb-2">{range.name}</p>
                {
                  renderSpread(range.min, range.max, range.color, range.unit)
                }
              </div>
            )
          })
        }

        <div className="flex w-fit cursor-pointer items-center mt-10"
             onMouseEnter={(e) => mouseEnter(e, 'departments', 0, i)}
             onMouseLeave={() => mouseLeave()}>
          <div className="icon_smaller round pointer-events-none" style={{backgroundColor: colors[i]}}/>
          <h4 className="ml-2">{groupName[i]}</h4>
        </div>
      </div>
    </div>
  )
}

export const showGroupChange = (i: number, current: number, desired: number, mouseEnter: Function,
                                mouseLeave: Function, fromStart: boolean, ranges: Array<RangeInterface>,
                                statement?: string, areaName?: string) => {

  const currentName = fromStart ? "Start" : "Current"
  const desiredName = fromStart ? "Current" : "Desired"
  const showSign = fromStart

  return (
    <div key={"customer_summary_" + i} className="border_gray border_radius p-4 mt-8 flex flex-col justify-between"
         style={{height: "calc(100% - 64px)"}}>
      <div>
        {
          areaName &&
          statement ? <div className="color_dark_gray font_small">{areaName}</div> : <h2>{areaName}</h2>
        }
        {
          statement &&
            <h3 className="mt-1">
                &quot;{statement}&quot;
            </h3>
        }
      </div>

      <div>
        <div className="mt-10">

          <p className="font_small bold">{fromStart ? 'Progress' : 'Desired change'}</p>

          <div className="mt-2 flex items-center">
            {
              fromStart && <img alt='up' className="icon_large mr-4" src={getArrow(i, current, desired)} />
            }

            <div>
              <h2>{showPercentageUnits(current, desired, showSign, 0, 'pp')}</h2>
              <p className="color_dark_gray font_smaller">Percentage points</p>
            </div>

            {
              fromStart ? (
                <img alt='up' className="icon_large ml-6 mr-4" src={getArrow(i, current, desired)} />
              ) : (
                <div style={{width: "34%"}}/>
              )
            }

            <div>
              <h2>{showPercent(current, desired, showSign, 0, '%')}</h2>
              <p className="color_dark_gray font_smaller">Percent</p>
            </div>
          </div>
        </div>

        <div className="mt-10">
          <div className="mt-2 flex">
            <div className="mr-12">
              <p className="font_small bold mb-2">{currentName} state</p>
              <div style={{width: "80px"}}>
                {circleValue(current, colorsStart[i])}
              </div>
            </div>

            <div className="ml-12 pl-8">
              <p className="font_small bold mb-2">{desiredName} state</p>
              <div style={{width: "80px"}}>
                {circleValue(desired, colors[i])}
              </div>
            </div>
          </div>
        </div>

        {
          ranges.map((range, nr) => {
            return (
              <div className="mt-10" key={"range_" + i + "_" + nr}>
                <p className="font_small bold mb-2">{range.name}</p>
                {
                  range.min < 0 ? renderSpreadNegative(range.min, range.max, range.color, range.unit) :
                    renderSpread(range.min, range.max, range.color, range.unit)
                }
              </div>
            )
          })
        }

        <div className="flex w-fit cursor-pointer items-center mt-10"
             onMouseEnter={(e) => mouseEnter(e, 'departments', 0, i)}
             onMouseLeave={() => mouseLeave()}>
          <div className="icon_smaller round pointer-events-none" style={{backgroundColor: colors[i]}}/>
          <h4 className="ml-2">{groupName[i]}</h4>
        </div>
      </div>
    </div>
  )
}
