import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import Header from "../../components/header/Header";
import Api from "../../services/Api";
import { changeUrl } from "../../services/Navigation";

export default class CreateSession extends React.Component<{}>  {

  api = Api.getInstance()

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const data = {
      key: urlParams.get('key')
    }
    this.api.post('v1/fetch_session', data).then(response => {
      this.api.logIn(response.json.token, response.json.user, response.json.customers)
      changeUrl('/');
    })
  }

  render () {
    return (
      <IonPage>
        <IonContent>
          <Header name="Zebrain customer"/>
          <div className="page_content">
            <div className="page_section">
              <p>Session not found</p>
            </div>
          </div>
        </IonContent>
      </IonPage>
    )
  }
}
