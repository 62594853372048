import {IonContent, IonPage, IonLabel, IonSegment, IonSegmentButton, IonToggle} from '@ionic/react';
import React from "react";
import Header from "../../components/header/Header";
import {BreadcrumbInterface} from "../../components/header/interfaces";
import Api from "../../services/Api";
import {connect, ConnectedProps} from "react-redux";
import {sortTable} from "../../helpers";
import {EsgInterface} from "./interfaces";
import SpeedoMeter from "../heatmap/SpeedoMeter";

const mapState = (state: any) => ({
  navigation: state.navigation
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>

interface EsgArea {
  name: string
  current: number
}

interface EsgAllInterface {
  areas: Array<EsgArea>
  measuresAllCount: number
}

interface ComponentState {
  loaded: boolean
  tooFew: boolean
  esg: EsgInterface
  esgProgress: EsgInterface
  esgAll: EsgAllInterface
  periodType: string
  showAll: string
  showAllProgress: string
}



class Esg extends React.Component<PropsFromRedux, ComponentState>  {

  api = Api.getInstance()
  sortOrders: Array<boolean> = []

  state = {
    loaded: false,
    esg: {} as EsgInterface,
    esgProgress: {} as EsgInterface,
    esgAll: {} as EsgAllInterface,
    tooFew: true,
    periodType: 'y',
    showAll: 'n',
    showAllProgress: 'n'
  }

  componentDidMount() {
   this.getData()
  }

  getData = () => {
    this.api.get('v1/esg/' + this.api.profile.customer?.id + '?period_type=' + this.state.periodType)
      .then(response => {
        if (response.json.esg) {
          this.sortOrders = [true, false]
          for (let i = 0; i < response.json.esg.periods.length; i++) {
            this.sortOrders.push(false)
          }
        }

        this.setState({
          loaded: true,
          esg: response.json.esg,
          esgProgress: response.json.esgProgress,
          esgAll: response.json.esgAll,
          tooFew: response.json.tooFew,
        })
    })
  }

  sortCol = (col: number, tableId: string) => {
    this.sortOrders[col] = !this.sortOrders[col]
    sortTable(tableId, col, this.sortOrders[col], 1, 0)
  }

  renderTableHead = (tableId: string, headers: Array<string>, showAll: boolean) => {
    const narrowColStart = showAll ? 2 : 1
    return (
      <>
        <colgroup>
          {
            headers.map((header, i) => {
              let colStyle = i % 2 === 1 ? 'col_bg' : ''
              let colWidth = i < narrowColStart ? 'unset' : '104px'
              return (
                <col key={"col_" + tableId + "_" + i} className={colStyle} style={{width: colWidth}}/>
              )
            })
          }
        </colgroup>
        <thead>
        <tr className="align-top">
          {
            headers.map((name: string, i) => {
              let tdName = name
              let subText = ''
              if (name.indexOf('\t') > -1) {
                const arr = name.split('\t')
                tdName = arr[0]
                subText = arr[1]
              }
              return (
                <td key={"th_" + tableId + "_" + i} onClick={() => this.sortCol(i, tableId)}
                    className={"cursor-pointer underline" + (i >= narrowColStart ? " text_center": " align-middle")}>
                  <h3>{tdName}</h3>
                  {
                    subText.length > 0 &&
                    <div className="font_smaller">{subText}</div>
                  }
                  {
                    (tableId === "t" && i >= narrowColStart) &&
                      <div className="mt-2 font_small whitespace-nowrap"
                           style={{textDecoration: 'none', display: 'inline-block'}}>
                          Clients: {this.state.esg.rows[0].cols[i - narrowColStart]}
                      </div>
                  }
                </td>
              )
            })
          }
        </tr>
        </thead>
      </>
    )

  }

  changePeriod = (periodType: string|undefined) => {
    if (periodType) {
      this.setState({periodType: periodType}, () => this.getData())
    }
  }

  changeShowAll = () => {
    const all = this.state.showAll === 'y' ? 'n' : 'y'
    this.setState({showAll: all})
  }

  changeShowAllProgress = () => {
    const all = this.state.showAllProgress === 'y' ? 'n' : 'y'
    this.setState({showAllProgress: all})
  }

  render () {
    const breadcrumbs: Array<BreadcrumbInterface> = [
      {
        name: 'Overview',
        link: '/'
      },
      {
        name: 'Heatmap',
        link: '/heatmap'
      }
    ]
    if (!this.state.loaded) {
      return (
        <IonPage>
          <IonContent>
            <Header name="Esg" breadcrumbs={breadcrumbs} />
            <div className="page_content">
              <div className="page_section">Loading...</div>
            </div>
          </IonContent>
        </IonPage>
      )
    }

    if (this.state.tooFew) {
      return (
        <IonPage>
          <IonContent>
            <Header name="Esg" breadcrumbs={breadcrumbs} reload={this.getData}/>
            <div className="page_content">
              <div className="page_section">
                <h1>Too few client measurements</h1>
                <p className="top_margin">
                  This view will be available when at least five clients have measured their potential.
                </p>
              </div>
            </div>
          </IonContent>
        </IonPage>
      )
    }

    const tableHeaders = ["Area"]
    if (this.state.showAll !== 'n') {
      tableHeaders.push("Question")
    }
    for (const period of this.state.esg.periods) {
      tableHeaders.push(period)
    }

    const tableHeadersProgress = ["Area"]
    if (this.state.showAllProgress !== 'n') {
      tableHeadersProgress.push("Question")
    }
    tableHeadersProgress.push("Start")
    tableHeadersProgress.push("Current")
    tableHeadersProgress.push("Change\t(points)")
    tableHeadersProgress.push("Change\t(percent)")

    return (
      <IonPage>
        <IonContent>
          <Header name="Social Sustainability" breadcrumbs={breadcrumbs} reload={this.getData}/>
          <div className="page_content">
            <div className="page_section">
              <h1 className="flex items-center">
                Social Sustainability
              </h1>
              <p className="mt-4">
                Track your key performance indicator (KPI) related to social sustainability
              </p>

              <h2 className="mt-8">Average Metrics</h2>
              <p className="mt-4 page_intro_text">
                The table shows the group’s average value for each KPI, during the selected time period.
                The indices are calculated using users’ measurements of potential and values ranges between 0-100.
              </p>
              {/*<p className="mt-4">{this.state.esg.clients} clients</p>*/}

              <div className="flex justify-between">
                <div className="flex items-center mt-4">
                  <IonLabel className="font_small">Show individual questions</IonLabel>
                  <IonToggle checked={this.state.showAll === 'y'} mode="ios" className="toggle_small ml-4"
                             onIonChange={(e) => this.changeShowAll()}/>
                </div>
                <div>
                  <IonSegment className="light" value={this.state.periodType} mode="ios"
                              onIonChange={(e) => this.changePeriod(e.detail.value)}>
                    <IonSegmentButton value="y">
                      <IonLabel>Year</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="h">
                      <IonLabel>Half year</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="q">
                      <IonLabel>Quarter</IonLabel>
                    </IonSegmentButton>
                  </IonSegment>
                </div>
              </div>

              <table className="data_table lines mt-8 w-full" id="t">
                {
                  this.renderTableHead("t", tableHeaders, this.state.showAll === 'y')
                }
                <tbody>
                {
                  this.state.esg.rows.map((row, index) => {
                    if (index === 0) {
                      // Client number is on first row. Hack :-/
                      return null
                    }
                    if (this.state.showAll === 'n' && row.type === 'question') {
                      return null
                    }
                    return (
                      <tr key={"row_" + index} className="h65">
                        <td className={row.type === 'question' ? '': 'h4'}>
                          {row.name}
                        </td>
                        {
                          this.state.showAll === 'y' &&
                            <td>
                              {row.type === 'area' ? "Average" : row.question}
                            </td>
                        }
                        {
                          row.cols.map((col: string, colNr) => {
                            return (
                              <td key={"col_" + index + "_" + colNr} className="text_center">{col}</td>
                            )
                          })
                        }
                      </tr>
                    )
                  })
                }
                </tbody>
              </table>

              <h2 className="mt-16">Progress</h2>

              <p className="mt-4 page_intro_text">
                The table shows users' first and latest measures, and the change in percent and percentage
                points between them.
                The metrics is based on {this.state.esgProgress.clients} users. Only users with two measurements
                or more are included.
              </p>

              {
                this.state.esgProgress.rows.length > 0 ?
                   (
                     <>
                       <div className="flex items-center mt-4">
                         <IonLabel className="font_small">Show individual questions</IonLabel>
                         <IonToggle checked={this.state.showAllProgress === 'y'} mode="ios" className="toggle_small ml-4"
                                    onIonChange={(e) => this.changeShowAllProgress()}/>
                       </div>

                       <table className="data_table lines mt-8 w-full" id="p">
                         {
                           this.renderTableHead("p", tableHeadersProgress, this.state.showAllProgress === 'y')
                         }
                         <tbody>
                         {
                           this.state.esgProgress.rows.map((row, index) => {
                             if (this.state.showAllProgress === 'n' && row.type === 'question') {
                               return null
                             }
                             return (
                               <tr key={"row_" + index} className="h65">
                                 <td className={row.type === 'question' ? '' : 'h4'}>
                                   {row.name}
                                 </td>
                                 {
                                   this.state.showAllProgress === 'y' &&
                                     <td>
                                       {row.question}
                                     </td>
                                 }
                                 {
                                   row.cols.map((col: string, colNr) => {
                                     return (
                                       <td key={"col_" + index + "_" + colNr} className="text_center">{col}</td>
                                     )
                                   })
                                 }
                               </tr>
                             )
                           })
                         }
                         </tbody>
                       </table>
                     </>
                  ) : (
                    <div className="mt-4">
                      Too few users for progress report
                    </div>
                  )
              }

              <h2 className="mt-16">Zebrain users average value</h2>

              <div className="mt-8 flex">
                {
                  this.state.esgAll.areas.map((area, i) => {
                    return (
                      <div key={"all_area_" + i} style={{width: "24%"}}>
                        <h3>{area.name}</h3>
                        <div className="flex mt-4 items-center">
                          <div style={{width: "50%"}}>
                            <SpeedoMeter value={area.current} />
                          </div>
                          <h1 style={{width: "50%"}} className="px-4">{area.current.toFixed(0)} %</h1>
                        </div>

                      </div>
                    )
                  })
                }
              </div>
            </div>

          </div>
        </IonContent>
      </IonPage>
    )
  }
}

export default connector(Esg)
