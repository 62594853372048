import React from 'react'


export const circleValue = (value: number, color: string) => {

  // colorStart = '#ffccc8'
  // colorEnd = '#bc80f0'
  let arc = ''
  // startArc = ''
  let randId = "clicp_" + Math.random()

  if (value === 100) {
    value = 99.9
  } else if (value === -100) {
    value = -99.9
  }
  let angle = 2 * Math.PI * value / 100
  const angleDeg = 360 * value / 100

  let largeArc = 0
  let largeArcInner = 0
  let sweep = angle > 0 ? 1 : 0
  const endXouter = 50 + 48 * Math.sin(angle)
  const endYouter = 50 - 48 * Math.cos(angle)
  const endXinner = 50 + 20 * Math.sin(angle)
  const endYinner = 50 - 20 * Math.cos(angle)
  if (value > 50 || value < -50) {
    largeArc = 1
  }

  arc = 'M 50 2 A 48 48 0 ' + largeArc + ' ' + sweep + ' ' + endXouter + ' ' + endYouter
    + ' L ' + endXinner + ' ' + endYinner + ' A 20 20 0 '  + largeArcInner + ' ' + sweep + ' 50 30 z'


  const randUrl = "url(#" + randId + ")"
  let startAngle = angleDeg >= 0 ? 0 : (360 + angleDeg)
  let stopAngle = angleDeg <= 0 ? 360 : angleDeg
  let startColor = color
  let stopColor = color
  const conicBackground = "conic-gradient(from 0deg at 50% 50%, " + startColor + " " + startAngle + "deg, "
    + stopColor + "  " + stopAngle + "deg)"
  let textValue = value.toFixed(0) + "%"

  return (

    <svg viewBox="0 0 100 100"
         xmlns="http://www.w3.org/2000/svg">
      <style>
        { `.valueText { font-size: 22px; font-weight: 600 }`}
      </style>
      <clipPath id={randId}>
        <path d={arc}/>
      </clipPath>

      <circle cx={50} cy={50} r={48} style={{fill: "#e5e5e9"}} />
      <foreignObject width="100" height="100" clipPath={randUrl}>
        <div style={{height: "100%", width: "100%", background: conicBackground}}/>
      </foreignObject>

      <circle cx={50} cy={50} r={35} fill="#ffffff"/>

      <text x="50%" y="50%" string={textValue} className="valueText" dominantBaseline="middle" textAnchor="middle">
        {value.toFixed(0)}%
      </text>

    </svg>
  )
}
