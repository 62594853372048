import store from "../redux/store";
import {CONFIG} from "../constants"
import {err_language_en} from "./language_en";
import {TOKEN_REMOVE, TOKEN_STORE, CUSTOMER_SELECTED, CUSTOMER_REMOVED} from "../redux/actionTypes";
import {CustomerInterface, UserDepartmentInterface} from "./interfacesApi";

interface UserInterface {
  id: number
  email: string
  firstName: string
  lastName: string
}

interface ProfileInterface {
  customers: Array<CustomerInterface>,
  customer?: CustomerInterface
  userDepartments: UserDepartmentInterface
  user?: UserInterface,
}

interface ApiCallResponse {
  response: Response,
  json?: any,
  isExpectedError: boolean,
  errorName?: string,
  errorMessage?: string,
}

export enum LogLevel {
  CRITICAL = "CRITICAL",
  ERROR = "ERROR",
  WARNING = "WARNING",
  INFO = "INFO",
  DEBUG = "DEBUG",
}

export default class Api {

  static myInstance: Api;
  private headers: any;
  private baseUrl: string;
  public loggedIn: boolean;
  public profile: ProfileInterface;
  public token: string;
  private errTexts: {[key: string] : string} = err_language_en

  constructor() {
    this.loggedIn = false;
    this.baseUrl = CONFIG.API_ENDPOINT;
    this.profile = {customers: [], userDepartments: {} as UserDepartmentInterface}
    this.headers = {
      'X-Version': CONFIG.VERSION,
      'X-platform': 'unknown',
      'Content-Type': 'application/json'
    };

    this.token = window.localStorage[CONFIG.TOKEN_NAME];
    const sessionData = window.localStorage[CONFIG.SESSION_DATA]

    if (this.token && sessionData) {
      this.loggedIn = true;
      this.headers['Authorization'] = 'Bearer ' + this.token;
      this.profile = JSON.parse(sessionData)
      if (this.profile.customer) {
        store.dispatch({type: CUSTOMER_SELECTED})
      }
    }
  }


  /**
   * @returns {ApiService}
   */
  static getInstance(): Api {
    if (Api.myInstance == null) {
      Api.myInstance = new Api();
    }

    return this.myInstance;
  }

  public trErr = (key: string) => {
    let value = this.errTexts[key]
    if (value) {
      return value
    }
    return key
  }

  public logOut(nextUrl: string) {
    this.post('v1/logout', {}).then(_response => {
      this.loggedIn = false;
      this.profile = {customers: [], userDepartments: {} as UserDepartmentInterface}
      delete window.localStorage[CONFIG.TOKEN_NAME];
      delete window.localStorage[CONFIG.SESSION_DATA];
      if (nextUrl) {
        window.location.href = nextUrl
      }
    })
  }

  public logIn(token: string, user: any, customers: Array<any>) {
    this.profile = {
      user: user,
      customers: customers,
      userDepartments: {} as UserDepartmentInterface
    }

    if (customers.length === 1) {
      this.profile.customer = customers[0]
      store.dispatch({type: CUSTOMER_SELECTED})
    } else {
      this.removeCustomer()
    }

    window.localStorage[CONFIG.TOKEN_NAME] = token
    this.storeProfile()
    this.headers['Authorization'] = 'Bearer ' + token
    this.loggedIn = true
    this.token = token
    store.dispatch({type: TOKEN_STORE, payload: token})
  }

  public storeProfile = () => {
    window.localStorage[CONFIG.SESSION_DATA] = JSON.stringify(this.profile)
  }

  public selectCustomer = (customer: CustomerInterface) => {
    this.profile.customer = customer
    this.storeProfile()
    store.dispatch({type: CUSTOMER_SELECTED})
  }

  public removeCustomer = () => {
    this.profile.customer = undefined
    this.storeProfile()
    store.dispatch({type: CUSTOMER_REMOVED})
  }

  public get(url: string, expectedErrors: Array<string> = []): Promise<ApiCallResponse> {
    const call = this.getRaw(url);
    return this.makeCall(call, expectedErrors)
  }

  public post(url: string, data: any, expectedErrors: Array<string> = []): Promise<ApiCallResponse> {
    const call = this.postRaw(url, data)
    return this.makeCall(call, expectedErrors)
  }

  private getRaw(url: string) {

    return fetch(this.baseUrl + url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: this.headers,
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
    });
  }

  private postRaw(url: string, data: any) {

    return fetch(this.baseUrl + url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: this.headers,
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
  }

  private makeCall(apiCall: Promise<Response>, expectedErrors: Array<string>): Promise<ApiCallResponse> {
    return new Promise((resolve, reject) => {
      apiCall
      // We got an http response
      .then((response) => {

        const result: ApiCallResponse = {
          response: response,
          isExpectedError: false,
        };

        if (response.ok) {
          // Call was successful (2XX status code)
          response.json().then(json => {
            result.json = json
            resolve(result);
          })
          .catch(() => {
            // This is for status 204 which will have an empty body (ie no json)
            resolve(result);
          });
        } else {
          // We got an error (most likely 4XX or 5XX status code)
          response.json().then(json => {
            result.json = json
            result.errorName = result.json.name;
            result.errorMessage = result.json.message;
            result.isExpectedError = expectedErrors.includes(result.errorName!)
            if (!result.isExpectedError) {
              if (response.status === 401) {
                // User session has most likely expired server side
                store.dispatch({type: TOKEN_REMOVE})
              } else {
                this.handleUnexpectedError(result)
              }
            }
            reject(result)
          })
          .catch(() => {
            // Response body is not json
            this.handleUnexpectedError(result)
            reject(result);
          });
        }
      })

      // We got no http response (eg due to timeout or network error)
      .catch(reason => {
        console.error(reason)
        this.handleUnexpectedError()
      });
    });
  }

  private handleUnexpectedError(response?: ApiCallResponse) {
    let alertMsg: string = 'Unexpected error occurred'
    if (response) {
      // Log error to backend
      const logMsg = response.errorName ?
        response.errorName :
        'Status: ' + response.response.status
      this.log(LogLevel.ERROR, logMsg)

      const additionalMsg = response.errorName ?
        response.errorMessage + ' (' + response.errorName + ')' :
        response.response.statusText + ' (' + response.response.status + ')'
      alertMsg += '\nAdditional information: ' + additionalMsg
    }
    // Display error to user
    alert(alertMsg)
  }

  /**
   * Sends a log message to backend
   */
  public log(level: LogLevel, message: string) {
    const data = {
      level: level,
      message: message,
    }
    try {
      this.postRaw('v1/log', data)
    } catch(err) {
      console.log(err)
    }
  }
}
