import { IonContent, IonPage } from '@ionic/react';
import './Heatmap.css';
import React from "react";
import Header from "../../components/header/Header";
import { BreadcrumbInterface } from "../../components/header/interfaces";
import Api from "../../services/Api";

interface ComponentProps {
  navigation: any
}

interface ComponentState {}

export default class HeatmapReadMore extends React.Component<ComponentProps, ComponentState>  {

  api = Api.getInstance()

  componentDidMount() {
    this.api.get('v1/log/' + this.api.profile.customer?.id + '/' + 11).then(_result => {
      // console.log(_result.json)
    })
  }

  render() {
    const breadcrumbs: Array<BreadcrumbInterface> = [
      {
        name: 'Overview',
        link: '/'
      },
      {
        name: 'Heatmap',
        link: '/heatmap'
      }
    ]
    return (
      <IonPage>
        <IonContent>
          <Header name="About potential" breadcrumbs={breadcrumbs}/>
          <div className="page_content">
            <div className="page_section">
              <h1>About Potential</h1>
              <div style={{ 'maxWidth': '601px' }} className="top_margin">
                We divide potential into four areas: Self Insight, Basic Energy, Motivation
                and Self Leadership which together constitutes an individual's total potential.
              </div>
              <div className="flex page_section_direction top_margin">
                <div className="page_half_section">
                  <div style={{ height : '100%' }} className="flex flex-col justify-between">
                    <div>
                      <h4>Self Insight</h4>
                      <div>Who am I?</div>
                      <div>The foundation for all development and success.</div>
                    </div>
                    <div>
                      <h4 className="top_margin">Basic Energy</h4>
                      <div>How do I feel?</div>
                      <div>The absence of harmful stress.</div>
                    </div>
                    <div>
                      <h4 className="top_margin">Motivation</h4>
                      <div>How motivated am I?</div>
                      <div>What do I need to make me feel motivated and to perform?</div>
                    </div>
                    <div>
                      <h4 className="top_margin">Self Leadership</h4>
                      <div>How do I reach my full potential?</div>
                      <div>The ability to understand and to act, based on what is most important to us.</div>
                    </div>
                  </div>
                </div>
                <div className="page_half_section">
                  <div style={{ margin: '0 auto' }} className='heatmap_diagram relative'>
                    <img width={'100%'} src="/assets/icon/heatmap/heatmap_diagram.png" alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="page_section top_margin">
              <h1>Self Insight</h1>
              <div className='top_margin' style={{ maxWidth: '698px' }}>
                Self insight is the foundation to all development and success, and
                constitutes who I am and what I want. It is an important and imperative
                foundation for a successful self leadership, this fact is also well-documented
                in research.
              </div>
              <div className='top_margin' style={{ maxWidth: '698px' }}>
                To be aware of your strengths and understand your motivating force - as well as your
                obstacles - is as crucial for the company as it is for the individual. The more
                aware we are and know what is important in our lives, what we want to achieve,
                our strengths and our limitations, the higher our chance is to accomplish our goals,
                both in our private lives and as an employee/employer.
              </div>
            </div>

            <div className="page_section top_margin">
              <h1>Basic Energy</h1>
              <div className='top_margin' style={{ maxWidth: '698px' }}>
                When we say Basic energy we are referring to the absence of harmful stress in our lives.
                Stress on its own is not damaging or negative, and our bodies are well equipped to handle
                stress during shorter periods of time. However, we need to rest and recover every day to minimize
                the risk of damaging both our bodies and our mental capacity.
              </div>
              <div className='top_margin' style={{ maxWidth: '698px' }}>
                Our test is built on the stress tests that are used in research and treatments both in Sweden
                and internationally. One of the tests included is KEDS, Karolinska Exhaustion Disorder Scale.
                The consequences of negative stress on both your own health and the effects it creates at your work,
                are well-documented in research.
              </div>
            </div>

            <div className="page_section top_margin">
              <h1>Motivation</h1>
              <div className='top_margin' style={{ maxWidth: '698px' }}>
                Motivation constitutes what we need in our lives to feel motivated and to perform well.
                Sometimes, we feel very motivated without it leading to anything concrete, other times we
                are able to perform and execute without any motivation or desire. In our model, we separate
                between two different types of motivational factors:
              </div>
              <div className='top_margin' style={{ maxWidth: '698px' }}>
                <h4>A. Extinguishing factors are required or we will lose motivation.</h4>
                These factors need to be sufficiently fulfilled to not diminish our motivation.
                <div>&#8212; Clarity.</div>
                <div>&#8212; To know what is expected.</div>
                <div>&#8212; Co-operation.</div>
                <div>&#8212; Community.</div>
                <div>&#8212; Relationships.</div>
                <h4 className="top_margin">Ignition factors increase our motivation the more we receive from them.</h4>
                <div>&#8212; Having a purpose.</div>
                <div>&#8212; Being interested.</div>
                <div>&#8212; To be in charge.</div>
                <div>&#8212; See yourself succeed.</div>
                <div>&#8212; Others point out that you are succeeding.</div>
              </div>
              <div className='top_margin' style={{ maxWidth: '698px' }}>
                The method we have developed, along with our algorithms and our motivation test, are amongst others
                based on the Self Determination Theory (SDT) which was developed in the 1970's by Edward Deci and Richard
                Ryan, Kenneth W Thomas, and research by Teresa Amabile, Head of Research at Harvard Business School. Our
                method is also based on modern research which shows how praise, above all unexpected, affects your
                inner motivation positively.
              </div>
            </div>

            <div className="page_section top_margin">
              <h1>Self Leadership</h1>
              <div className='top_margin' style={{ maxWidth: '698px' }}>
                The first three areas we measure and follow up on - Self insight. Basic energy and Motivation - aim
                to effectively use the fourth area: Self Leadership. It is crucial for your development to yield optimised
                effects included ROI (Return Of Investment).
              </div>
              <div className='top_margin' style={{ maxWidth: '698px' }}>
                With Self leadership we mean the ability to work on our own to achieve what is important for us.
                The test is built on factors which are behind how well we succeed with this, the factors that we can
                affect ourselves. Exactly what factors these are depend on what you have chosen to work with during
                your developmental process.
              </div>
              <div className='top_margin' style={{ maxWidth: '698px' }}>
                The test, our methods, are built on factors behind how well we succeed with something important to us.
                Regardless if your motivation stems from improvements to your private or professional life, our algorithm
                can pick out and differentiate these motiviating factors to make the appropriate recommendations.
              </div>
              <div className='top_margin' style={{ maxWidth: '698px' }}>
                The term 'Self leadership' was first introduced in literature (Manz 1986; Manz & Sims 1991) wuth the
                purpose of working more efficiently. This research shows that individuals who practice a systematic
                self leadership process have a larger likelihood to perform well, both in their private and professional
                life (DiLiello and Houghton 2006).
              </div>
              <div className='top_margin' style={{ maxWidth: '698px' }}>
                Sharon Parker is one, out of many, who have proved inner motivation is
                necessary to achieve self leadership, but not enough to achieve success.
                To succeed it is important to set clear goals and clear expectations.
                (Gisela Bäcklander, doctoral dissertation from KTH, "Autonomous, yet Aligned:
                Challenges of self-leadership in context" 2020).
              </div>
            </div>

          </div>
        </IonContent>
      </IonPage>
    )
  }
}
