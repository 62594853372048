import {IonContent, IonLabel, IonPage, IonSegment, IonSegmentButton, IonToggle} from '@ionic/react';
import './Heatmap.css';
import React from "react";
import Header from "../../components/header/Header";
import {BreadcrumbInterface} from "../../components/header/interfaces";
import Api from "../../services/Api";
import {AreaInterface, PerAreaInterface, RangeInterface, StatsInterface} from "./interfaces";
import { changeUrl } from "../../services/Navigation";
import {sortTable} from "../../helpers";
import {
  areas, areaPosFromNr, areaNrFromPos, colors, colorsDesired, colorAll, colorAllCompare,
  groupName
} from "./common";
import {BarGroupInterface, BarSubGroupInterface, BarValueInterface} from "../../components/graphics/interfaces";
import {renderSpread} from "./commonFunctions";
import BarChart from "../../components/graphics/BarChart";
import {showGroupValue, showGroupChange} from "./boxes";

interface MouseOverInterface {
  name: string
  areaNr: number
  groupNr: number
}

interface ComponentProps {
  navigation: any
  stats: StatsInterface
  reload: Function
  dataVersion: number
}

interface ComponentState {
  areaNr: number
  allQuestions: Array<AreaInterface>
  subPage: string
  showTables: boolean
  showAverage: boolean
  mouseOver: MouseOverInterface|undefined
  filterGroups: Array<number>
  showHighlights: boolean
}

export default class HeatmapPotential extends React.Component<ComponentProps, ComponentState>  {

  api = Api.getInstance()
  sortOrders: Array<boolean> = [true, false, false, false, false, false, false, false]

  groups = this.props.stats.customerSummaryList.length

  state = {
    allQuestions: [],
    showTables: false,
    showAverage: false,
    areaNr: this.props.navigation.sub2 ? parseInt(this.props.navigation.sub2) : 0,
    subPage: this.props.navigation.sub2 ? this.props.navigation.sub2 : '0',
    mouseOver: undefined,
    filterGroups: [0, 1, 2, 3],
    showHighlights: true
  }

  componentDidMount() {
    this.summarizeData()
    this.api.get('v1/log/' + this.api.profile.customer?.id + '/' + 9).then()
  }

  componentDidUpdate(prevProps: ComponentProps, prevState: ComponentState, snapshot: any) {
    if (this.props.dataVersion !== prevProps.dataVersion) {
      this.summarizeData()
    }
    if (this.props.navigation.sub2 !== prevProps.navigation.sub2) {
      const subPage = this.props.navigation.sub2 ? this.props.navigation.sub2 : '0'
      this.setState({areaNr: parseInt(subPage), subPage: subPage})
    }
  }

  summarizeData = () => {
    this.groups = this.props.stats.customerSummaryList.length
    const allQuestions = []
    for (let area of areas) {
      if (area.nr > -1 && area.nr < 4) {
        for (let i = 0; i < this.props.stats.customerAreasList[0][area.nr].questions.length; i++) {
          for (let groupNr = 0; groupNr < this.groups; groupNr++) {
            if (this.props.stats.customerAreasList[groupNr]) {
              const question = this.props.stats.customerAreasList[groupNr][area.nr].questions[i]
              question.question.areaName = area.name
              question.group = groupNr
              allQuestions.push(question)
            }
          }
        }
      }
    }

    this.setState({allQuestions: allQuestions})
  }

  toggleFilterGroups = (group: number) => {
    const remove = this.state.filterGroups.indexOf(group) > -1
    let filterGroups: Array<number>
    if (remove) {
      filterGroups = []
      for (const g of this.state.filterGroups) {
        if (g !== group) {
          filterGroups.push(g)
        }
      }
    } else {
      filterGroups = this.state.filterGroups
      filterGroups.push(group)
    }
    this.setState({filterGroups: filterGroups})
  }

  showInfoBoxes = () => {
    return (
      <div className="ml-8 ">
        <div className='box_gray top_margin font_small heatmap_info_width'>
          <h4>Current state point scale (%)</h4>
          <div className='mt-2'>0 – 30 = Very low</div>
          <div>31 – 55 = Low</div>
          <div>56 – 65 = Average</div>
          <div>66 – 80 = Good</div>
          <div>81 – 100 = Very good</div>
        </div>

        <div className='box_gray top_margin'>
          <h4>Percentage points (pp)</h4>
          <p className='mt-4 font_small'>
            Percentage points (pp) describes the difference between two percentages
          </p>
          <p className="mt-4 font_small">
            Example: Between 20% and 25% there is a difference of 5 percentage points but 25 percent.
          </p>
        </div>
      </div>
    )
  }

  renderTable = (id: string, areaNr: number) => {
    let addSortCol = 0
    let areaColNr = 0
    const showGroups = this.groups > 1
    const showArea = areaNr === 0
    if (showGroups) {
      addSortCol++
      areaColNr = 1
    }
    if (showArea) {
      addSortCol++
    }
    const groupNrs: Array<number> = []
    for (let i = 0; i < this.groups; i++) {
      groupNrs.push(i)
    }
    // let questionsFiltered = areaNr === 0 ? this.state.allQuestions :
    //   this.state.allQuestions.filter((question: AreaInterface) => (question.question.areaNr === areaNr))
    let questionsFiltered = this.state.allQuestions
    if (areaNr !== 0) {
      questionsFiltered = questionsFiltered.filter((question: AreaInterface) => (question.question.areaNr === areaNr))
    }

    if (this.state.filterGroups.length !== 4) {
      questionsFiltered = questionsFiltered.filter((question: AreaInterface) => (this.state.filterGroups.indexOf(question.group) > -1))
    }

    return (
      <>
        {
          this.groups > 1 &&
          <>
            <h4 className="top_margin">Filter by group</h4>
            <div className="mt-4 flex">
              {
                groupNrs.map(group => {
                  return (
                    <div className="mr-4 flex" key={"filter_group_" + group} onClick={() => this.toggleFilterGroups(group)}>
                      <div className={"checkbox" + (this.state.filterGroups.indexOf(group) > -1 ? " checked" : "")}/>
                      <div className="ml-2">{groupName[group]}</div>
                    </div>
                  )
                })
              }
            </div>
          </>
        }

        <table className="data_table lines mt-8" id={id}>
          <colgroup>
            {showGroups && <col/>}
            {showArea && <col/>}
            <col/>
            <col/>
            <col style={{backgroundColor: "#f6faef"}}/>
            <col/>
            <col style={{backgroundColor: "#f6faef"}}/>
            <col/>
            <col style={{backgroundColor: "#f6faef"}}/>
          </colgroup>
          <thead>
          <tr className="align-top">
            {
              showGroups &&
                <th onClick={() => this.sortCol(0)} className="cursor-pointer underline">Group</th>
            }
            {
              showArea &&
                <th onClick={() => this.sortCol(areaColNr)} className="cursor-pointer underline">Area</th>
            }
            <th onClick={() => this.sortCol(addSortCol)} className="cursor-pointer underline">Statement</th>
            <th onClick={() => this.sortCol(addSortCol + 1)} className="cursor-pointer underline">Current</th>
            <th onClick={() => this.sortCol(addSortCol + 2)} className="cursor-pointer underline">Desired</th>
            <th onClick={() => this.sortCol(addSortCol + 3)} className="cursor-pointer underline">Desired change</th>
            <th onClick={() => this.sortCol(addSortCol + 4)} className="cursor-pointer underline">Spread current</th>
            <th onClick={() => this.sortCol(addSortCol + 5)} className="cursor-pointer underline">Spread desired</th>
          </tr>
          </thead>
          <tbody>
          {
            questionsFiltered.map((question: AreaInterface, index) => {
              return (
                <tr key={"total_question_" + index + "_" + index}>
                  {
                    showGroups &&
                      <td className="whitespace-nowrap">{groupName[question.group]}</td>
                  }
                  {
                    showArea &&
                      <td className="whitespace-nowrap">{question.question.areaName}</td>
                  }
                  <td>{question.question.question}</td>
                  <td>{question.current.toFixed(1)}</td>
                  <td>{question.desired.toFixed(1)}</td>
                  <td>{question.gap.toFixed(1)}</td>
                  <td>{question.currentMin} - {question.currentMax}</td>
                  <td>{question.desiredMin} - {question.desiredMax}</td>
                </tr>
              )
            })
          }
          </tbody>
        </table>
      </>
    )
  }

  sortCol = (col: number) => {
    this.sortOrders[col] = !this.sortOrders[col]
    sortTable("t", col, this.sortOrders[col])
  }

  showUsers = () => {
    let groups = this.props.stats.measuresPerGroup.length
    if (this.state.showAverage) {
      groups++
    }
    const width = groups > 4 ? "150px" : "187.5px"
    return (
      <div className="flex" style={{width: "800px", padding: "0 0 0 50px"}}>
        {
          this.props.stats.measuresPerGroup.map((measures, i) => {
            return (
              <div key={"group_users_" + i} style={{width: width}}>
                <div className="flex cursor-pointer items-center w-fit"
                     onMouseEnter={(e) => this.mouseEnter(e, 'departments', this.state.areaNr, i)}
                     onMouseLeave={() => this.mouseLeave()}>
                  <div className="icon_smaller round pointer-events-none" style={{backgroundColor: colors[i]}}/>
                  <h4 className="ml-2">{groupName[i]}</h4>
                </div>
                <div className="font_small" style={{marginLeft: "20px"}}>{measures} users</div>
              </div>
            )
          })
        }
        {
          this.state.showAverage &&
            <div style={{width: width}}>
                <div className="flex cursor-pointer items-center w-fit"
                     onMouseEnter={(e) => this.mouseEnter(e, 'zebrain', 0, 0)}
                     onMouseLeave={() => this.mouseLeave()}>
                    <div className="icon_smaller round pointer-events-none" style={{backgroundColor: colorAll}}/>
                    <h4 className="ml-2">Zebrain</h4>
                </div>
                <div className="font_small" style={{marginLeft: "20px"}}>~1100 users</div>
            </div>
        }
      </div>
    )

  }

  generateHtml = (name: string, current: number, desired: number, color: string) => {
    let percentage = "-"
    if (current) {
      percentage = (100 * (desired - current) / current).toFixed(0) + '%'
    }
    return `<div class="flex items-center">
              <div class="icon_small round" style="background-color: ${color}"></div>
              <h4 class="ml-2">${name}</h4>
            </div>
            <p class="mt-4">Current state: <span class="bold">${current.toFixed(0)}%</span></p>
            <p class="mt-1">Desired state: <span class="bold">${desired.toFixed(0)}%</span></p>
            <p class="mt-1 whitespace-nowrap">Desired change: <span class="bold">${(desired - current).toFixed(0)}pp</span> 
               (percentage points)</p>
            <p class="mt-1">Desired change: <span class="bold">${percentage}</span></p>`
  }

  showBarCharts = () => {
    const barGroups: Array<BarGroupInterface> = []
    if (this.state.areaNr === 5) {
      for (const area of areas) {
        if (area.nr >= 4) {
          break
        }

        const subGroups: Array<BarSubGroupInterface> = []

        let groupNr = 0
        for (const customerSummary of this.props.stats.customerSummaryList) {

          if (customerSummary) {
            let data: any
            if (area.nr === -1) {
              data = customerSummary
            } else {
              data = customerSummary.areas[area.nr]
            }

            const values: Array<BarValueInterface> = []

            values.push({
              value: data.current,
              color: colors[groupNr]
            })
            values.push({
              value: data.desired,
              color: colorsDesired[groupNr]
            })

            subGroups.push({
              values: values,
              infoName: area.nr === -1 ? 'total': 'area',
              groupNr: groupNr,
              areaNr: area.nr,
              html: this.generateHtml(groupName[groupNr], data.current, data.desired, colors[groupNr])
            })
          }

          groupNr++
        }

        if (this.state.showAverage) {
          let data: any
          if (area.nr === -1) {
            data = this.props.stats.all
          } else {
            data = this.props.stats.all.areas[area.nr]
          }
          const values: Array<BarValueInterface> = [
            {
              value: data.current,
              color: colorAll
            },
            {
              value: data.desired,
              color: colorAllCompare
            },
          ]

          subGroups.push({
            values: values,
            infoName: 'average',
            groupNr: 0,
            areaNr: area.nr,
            html: this.generateHtml("Zebrain", data.current, data.desired, colorAll)
          })
        }

        const barGroup: BarGroupInterface = {
          name: area.name,
          subgroups: subGroups
        }
        barGroups.push(barGroup)
      }

      return <BarChart groups={barGroups} showAreaName={true}/>
    } else {
      const area = areas[this.state.areaNr]

      let groupNr = 0
      for (const customerSummary of this.props.stats.customerSummaryList) {

        const values: Array<BarValueInterface> = []
        if (customerSummary) {
          let data: any
          if (area.nr === -1) {
            data = customerSummary
          } else {
            data = customerSummary.areas[area.nr]
          }

          values.push({
            value: data.current,
            color: colors[groupNr]
          })
          values.push({
            value: data.desired,
            color: colorsDesired[groupNr]
          })

          const barGroup: BarGroupInterface = {
            name: groupName[groupNr],
            subgroups: [
              {
                values: values,
                infoName: area.nr === -1 ? 'total': 'area',
                groupNr: groupNr,
                areaNr: area.nr,
                html: this.generateHtml(groupName[groupNr], data.current, data.desired, colors[groupNr])
              }
            ]
          }
          barGroups.push(barGroup)
        }
        groupNr++
      }

      if (this.state.showAverage) {
        let data: any
        if (area.nr === -1) {
          data = this.props.stats.all
        } else {
          data = this.props.stats.all.areas[area.nr]
        }
        const values: Array<BarValueInterface> = [
          {
            value: data.current,
            color: colorAll
          },
          {
            value: data.desired,
            color: colorAllCompare
          },
        ]

        const subGroups: Array<BarSubGroupInterface> = [
          {
            values: values,
            infoName: 'average',
            groupNr: 0,
            areaNr: area.nr,
            html: this.generateHtml("Zebrain", data.current, data.desired, colorAll)
          }
        ]
        barGroups.push({
          name: 'Zebrain',
          subgroups: subGroups
        })
      }

      return <BarChart groups={barGroups} />
    }
  }

  showPotentialTables = () => {
    const areaNumbers = [0, 1, 2, 3, 4]

    return (
      <>
        <div className="bg_lighter_gray p-2 cursor-pointer"
             onClick={() => this.setState({showTables: !this.state.showTables})}>
          <div className="flex items-center justify-center">
            <p className="font_small">Show table</p>
            <img alt='toggle' className="icon_small ml-4"
                 src={"/assets/icon/arrow_" + (this.state.showTables ? "up" : "down") + ".svg"}/>
          </div>

        </div>
        {
          this.state.showTables &&
          <>
            {areaNumbers.map(areaNumber => {
              return this.showPotentialTable(areaNumber, areas[areaNumber].name)
            })}
          </>
        }

      </>
    )
  }

  showPotentialTable = (areaNr: number, name: string) => {
    const areaNrI = areaPosFromNr(areaNr)
    let avgCurrent = 0
    let avgDesired = 0
    let avgGap = 0
    if (this.state.showAverage) {
      const avgArea =  areaNr === 0 ? this.props.stats.all : this.props.stats.all.areas[areaNrI]
      avgCurrent = avgArea.current
      avgDesired = avgArea.desired
      avgGap = avgDesired - avgCurrent
    }

    return (
      <table className="mt-8 heatmap_table_lines">
        <tbody>
        <tr className="border_bottom_gray">
          <td style={{width: "180px"}}><h4>{name}</h4></td>
          {
            this.props.stats.measuresPerGroup.map((measures, i) => {
              return (
                <td key={"p_t_name_" + i + "_" + areaNr}>
                  <div className="flex items-center"
                       onMouseEnter={(e) => this.mouseEnter(e, 'departments', areaNr, i)}
                       onMouseLeave={() => this.mouseLeave()}>
                    <div className="icon_smaller round" style={{backgroundColor: colors[i]}}/>
                    <h4 className="ml-1">{groupName[i]}</h4>
                  </div>
                </td>
              )
            })
          }
          {
            this.state.showAverage &&
            <td>
                <div className="flex items-center"
                     onMouseEnter={(e) => this.mouseEnter(e, 'zebrain', 0, 0)}
                     onMouseLeave={() => this.mouseLeave()}>
                    <div className="icon_smaller round" style={{backgroundColor: colorAll}}/>
                    <h4 className="ml-1">Zebrain</h4>
                </div>
            </td>
          }
        </tr>

        <tr className="border_bottom_gray font_small">
          <td>Current (%)</td>
          {
            this.props.stats.customerSummaryList.map((customerSummary, i) => {
              let value = "-"
              if (customerSummary) {
                const area = areaNr === 0 ? customerSummary : customerSummary.areas[areaNrI]
                value = area.current.toFixed(0)
              }
              return (
                <td key={"p_t_current_" + i + "_" + areaNr}>{value}</td>
              )
            })
          }
          {
            this.state.showAverage &&
              <td>
                {avgCurrent.toFixed(0)}
              </td>
          }

        </tr>

        <tr className="border_bottom_gray font_small">
          <td>Desired (%)</td>
          {
            this.props.stats.customerSummaryList.map((customerSummary, i) => {
              let value = "-"
              if (customerSummary) {
                const area = areaNr === 0 ? customerSummary : customerSummary.areas[areaNrI]
                value = area.desired.toFixed(0)
              }
              return (
                <td key={"p_t_desired_" + i + "_" + areaNr}>{value}</td>
              )
            })
          }
          {
            this.state.showAverage &&
              <td>
                {avgDesired.toFixed(0)}
              </td>
          }
        </tr>

        <tr className="border_bottom_gray font_small  align-top">
          <td>
            <p className="font_small">Desired change (pp)</p>
            <p className="font_smaller color_dark_gray">Percentage points</p>
          </td>
          {
            this.props.stats.customerSummaryList.map((customerSummary, i) => {
              let value = "-"
              if (customerSummary) {
                const area = areaNr === 0 ? customerSummary : customerSummary.areas[areaNrI]
                const change = area.desired - area.current
                value = change.toFixed(0)
              }
              return (
                <td key={"p_t_change_" + i + "_" + areaNr}>{value}</td>
              )
            })
          }
          {
            this.state.showAverage &&
              <td>
                {avgGap.toFixed(0)}
              </td>
          }
        </tr>
        <tr className="border_bottom_gray font_small">
          <td>
            Desired change (%)
          </td>
          {
            this.props.stats.customerSummaryList.map((customerSummary, i) => {
              let value = "-"
              if (customerSummary) {
                const area = areaNr === 0 ? customerSummary : customerSummary.areas[areaNrI]
                if (area.current > 0) {
                  const change = area.desired - area.current
                  value = (100 * (change / area.current)).toFixed(0)
                }

              }
              return (
                <td key={"p_t_change_" + i + "_" + areaNr}>{value}</td>
              )
            })
          }
          {
            this.state.showAverage &&
              <td>
                {avgGap.toFixed(0)}
              </td>
          }
        </tr>
        </tbody>
      </table>
    )
  }

  showTotal = () => {

    return (
      <>
        <div className="page_section">
          <h2 className="mt-4">Highlights</h2>
          <p className="mt-4 cursor-pointer" onClick={() => this.setState({showHighlights: !this.state.showHighlights})}>
            {
              this.state.showHighlights ? 'Hide highlights' : 'Show highlights'
            }
            <img className="icon_small align-middle ml-4"
                 src={"/assets/icon/arrow_" + (this.state.showHighlights ? "down" : "up") + ".svg"}/>
          </p>
          {
            this.state.showHighlights &&
            <>
                <h3 className="mt-8">Statement with largest desired change</h3>
                <div className="flex flex-wrap">
                  {
                    this.props.stats.customerAreasList.map((perArea: Array<PerAreaInterface>, i) => {
                      if (perArea) {
                        let g = perArea[0].highestGap
                        for (let i = 1; i < 4; i++) {
                          if (perArea[i].highestGap.gap > g.gap) {
                            g = perArea[i].highestGap
                          }
                        }
                        const ranges: Array<RangeInterface> = [
                          {
                            name: 'Range within current state',
                            min: g.currentMin,
                            max: g.currentMax,
                            unit: '%',
                            color: colors[i]
                          },
                          {
                            name: 'Range within desired state',
                            min: g.desiredMin,
                            max: g.desiredMax,
                            unit: '%',
                            color: colorsDesired[i]
                          }
                        ]
                        return (
                          <div key={"largest_change_" + i} className="heatmap_col_2 mr-8">
                            {showGroupChange(i, g.current, g.desired, this.mouseEnter, this.mouseLeave, false,
                              ranges, g.question.question, g.question.areaName)}
                          </div>
                        )
                      } else {
                        return (
                          <div key={"too_few_largest_gap_" + i} className="top_margin bg_lighter_gray p-5">
                            <div className="font_small color_dark_gray">{groupName[i]}</div>
                            <p>Not enough users</p>
                          </div>
                        )
                      }
                    })
                  }
                </div>

                <h3 className="mt-16">Statement with lowest desired change</h3>
                <div className="flex flex-wrap">
                  {
                    this.props.stats.customerAreasList.map((perArea: Array<PerAreaInterface>, i) => {
                      if (perArea) {
                        let g = perArea[0].lowestGap
                        for (let i = 1; i < 4; i++) {
                          if (perArea[i].lowestGap.gap < g.gap) {
                            g = perArea[i].lowestGap
                          }
                        }
                        const ranges: Array<RangeInterface> = [
                          {
                            name: 'Range within current state',
                            min: g.currentMin,
                            max: g.currentMax,
                            unit: '%',
                            color: colors[i]
                          },
                          {
                            name: 'Range within desired state',
                            min: g.desiredMin,
                            max: g.desiredMax,
                            unit: '%',
                            color: colorsDesired[i]
                          }
                        ]
                        return (
                          <div key={"largest_change_" + i} className="heatmap_col_2 mr-8">
                            {showGroupChange(i, g.current, g.desired, this.mouseEnter, this.mouseLeave, false,
                              ranges, g.question.question, g.question.areaName)}
                          </div>
                        )
                      } else {
                        return (
                          <div key={"too_few_largest_gap_" + i} className="top_margin bg_lighter_gray p-5">
                            <div className="font_small color_dark_gray">{groupName[i]}</div>
                            <p>Not enough users</p>
                          </div>
                        )
                      }
                    })
                  }
                </div>
            </>
          }

        </div>

        <div className="page_section top_margin">
          <h2>Per statement</h2>
          {this.renderTable("t", 0)}
        </div>
      </>
    )
  }

  tooFew = () => {
    return (
      <>Too few answers</>
    )
  }

  showOneArea = (areaNr: number) => {
    const area = areas[areaNr]

    return (
      <>
        <div className="page_section">
          <div className="flex items-center">
            <h3>Range</h3>
            <img alt='help' className="ml-2 icon_h2" src="/assets/icon/question_mark.svg"
                 onMouseEnter={(e) =>
                   this.mouseEnter(e, 'range', 0, 0)}
                 onMouseLeave={this.mouseLeave}
            />
          </div>

          <div className="flex top_margin">
            <div className="heatmap_col_2"><h4>Current state</h4></div>
            <div className="heatmap_col_2 ml-8"><h4>Desired state</h4></div>
          </div>

          {
            this.props.stats.customerSummaryList.map((customerSummary, groupNr) => {

              return (
                <div className={groupNr === 0 ? "mt-2" : "mt-16"} key={"range_spread_" + groupNr} >
                  <div className="flex items-center">
                    <div className="icon_smaller round" style={{backgroundColor: colors[groupNr]}}/>
                    <p className="ml-2 font_small">{groupName[groupNr]}</p>
                  </div>
                  <div className="flex mt-2">
                    <div className="heatmap_col_2">
                      {
                        customerSummary ?
                          renderSpread(
                            customerSummary.areas[area.nr].currentMin,
                            customerSummary.areas[area.nr].currentMax,
                            colors[groupNr], '%'):
                          this.tooFew()
                      }
                    </div>
                    <div className="heatmap_col_2 ml-8">
                      {
                        customerSummary ?
                          renderSpread(
                            customerSummary.areas[area.nr].desiredMin,
                            customerSummary.areas[area.nr].desiredMax,
                            colorsDesired[groupNr], '%'):
                          this.tooFew()
                      }
                    </div>
                  </div>
                </div>
              )
            })
          }

          <h2 className="mt-24">Highlights</h2>

          <p className="mt-4 cursor-pointer" onClick={() => this.setState({showHighlights: !this.state.showHighlights})}>
            {
              this.state.showHighlights ? 'Hide highlights' : 'Show highlights'
            }
            <img className="icon_small align-middle ml-4"
                 src={"/assets/icon/arrow_" + (this.state.showHighlights ? "down" : "up") + ".svg"}/>
          </p>

          {
            this.state.showHighlights &&
            <>
                <h3 className="mt-8">Statement with lowest current state</h3>
                <div className="flex flex-wrap">
                  {
                    this.props.stats.customerAreasList.map((perArea: Array<PerAreaInterface>, i) => {
                      if (perArea) {
                        const g = perArea[area.nr].lowestCurrent
                        const ranges: Array<RangeInterface> = [
                          {
                            name: 'Range within current state',
                            min: g.currentMin,
                            max: g.currentMax,
                            unit: '%',
                            color: colors[i]
                          },
                          {
                            name: 'Range within desired state',
                            min: g.desiredMin,
                            max: g.desiredMax,
                            unit: '%',
                            color: colorsDesired[i]
                          }
                        ]
                        return (
                          <div key={"largest_change_" + i} className="heatmap_col_2 mr-8">
                            {showGroupValue(i, g.current, g.desired, this.mouseEnter, this.mouseLeave, ranges,
                              g.question.question)}
                          </div>
                        )
                      } else {
                        return (
                          <div key={"too_few_largest_gap_" + i} className="top_margin bg_lighter_gray p-5">
                            <div className="font_small color_dark_gray">{groupName[i]}</div>
                            <p>Not enough users</p>
                          </div>
                        )
                      }
                    })
                  }
                </div>

                <h3 className="mt-16">Statement with highest current state</h3>
                <div className="flex flex-wrap">
                  {
                    this.props.stats.customerAreasList.map((perArea: Array<PerAreaInterface>, i) => {
                      if (perArea) {
                        const g = perArea[area.nr].highestCurrent
                        const ranges: Array<RangeInterface> = [
                          {
                            name: 'Range within current state',
                            min: g.currentMin,
                            max: g.currentMax,
                            unit: '%',
                            color: colors[i]
                          },
                          {
                            name: 'Range within desired state',
                            min: g.desiredMin,
                            max: g.desiredMax,
                            unit: '%',
                            color: colorsDesired[i]
                          }
                        ]
                        return (
                          <div key={"largest_change_" + i} className="heatmap_col_2 mr-8">
                            {showGroupValue(i, g.current, g.desired, this.mouseEnter, this.mouseLeave, ranges,
                              g.question.question)}
                          </div>
                        )
                      } else {
                        return (
                          <div key={"too_few_largest_gap_" + i} className="top_margin bg_lighter_gray p-5">
                            <div className="font_small color_dark_gray">{groupName[i]}</div>
                            <p>Not enough users</p>
                          </div>
                        )
                      }
                    })
                  }
                </div>

                <h3 className="mt-16">Statement with largest desired change</h3>
                <div className="flex flex-wrap">
                  {
                    this.props.stats.customerAreasList.map((perArea: Array<PerAreaInterface>, i) => {
                      if (perArea) {
                        const g = perArea[area.nr].highestGap
                        const ranges: Array<RangeInterface> = [
                          {
                            name: 'Range within current state',
                            min: g.currentMin,
                            max: g.currentMax,
                            unit: '%',
                            color: colors[i]
                          },
                          {
                            name: 'Range within desired state',
                            min: g.desiredMin,
                            max: g.desiredMax,
                            unit: '%',
                            color: colorsDesired[i]
                          }
                        ]
                        return (
                          <div key={"largest_change_" + i} className="heatmap_col_2 mr-8">
                            {showGroupChange(i, g.current, g.desired, this.mouseEnter, this.mouseLeave, false,
                              ranges, g.question.question)}
                          </div>
                        )
                      } else {
                        return (
                          <div key={"too_few_largest_gap_" + i} className="top_margin bg_lighter_gray p-5">
                            <div className="font_small color_dark_gray">{groupName[i]}</div>
                            <p>Not enough users</p>
                          </div>
                        )
                      }
                    })
                  }
                </div>

                <h3 className="mt-16">Statement with lowest desired change</h3>
                <div className="flex flex-wrap">
                  {
                    this.props.stats.customerAreasList.map((perArea: Array<PerAreaInterface>, i) => {
                      if (perArea) {
                        const g = perArea[area.nr].lowestGap
                        const ranges: Array<RangeInterface> = [
                          {
                            name: 'Range within current state',
                            min: g.currentMin,
                            max: g.currentMax,
                            unit: '%',
                            color: colors[i]
                          },
                          {
                            name: 'Range within desired state',
                            min: g.desiredMin,
                            max: g.desiredMax,
                            unit: '%',
                            color: colorsDesired[i]
                          }
                        ]
                        return (
                          <div key={"gap_" + i} className="heatmap_col_2 mr-8">
                            {showGroupChange(i, g.current, g.desired, this.mouseEnter, this.mouseLeave,false,
                              ranges, g.question.question)}
                          </div>
                        )
                      } else {
                        return (
                          <div key={"too_few_lowest_current_" + i} className="top_margin bg_lighter_gray p-5">
                            <div className="font_small color_dark_gray">{groupName[i]}</div>
                            <p>Not enough users</p>
                          </div>
                        )
                      }
                    })
                  }
                </div>
            </>
          }
        </div>

        <div className="page_section top_margin">
          <h2>Per statement</h2>
          {this.renderTable("t", area.nr + 1)}
        </div>
      </>
    )
  }

  changeSubpage = (subPage: string|undefined) => {
    if (subPage) {
      this.setState({subPage: subPage})
      changeUrl('/heatmap/potential/' + subPage)
    }
  }

  mouseEnter = (e: any, name: string, areaNr: number, groupNr: number) => {
    this.setState({
      mouseOver: {
        name: name,
        areaNr: areaNr,
        groupNr: groupNr
      } as MouseOverInterface
    }, () => {
      const overW = document.getElementById('mouse_over')
      if (overW) {
        const rect = e.target.getBoundingClientRect()
        const popHeight = overW.offsetHeight
        const elHeight = rect.bottom - rect.top
        const top = + elHeight / 2 - popHeight / 2
        overW.style.top = rect.top + top + 'px'
        overW.style.left = rect.right + 4 + 'px'
      }
    })
  }

  mouseLeave = () => {
    this.setState({mouseOver: undefined})
  }

  renderMouseOver = () => {
    if (this.state.mouseOver) {
      const m = this.state.mouseOver as MouseOverInterface
      if (m.name === 'departments') {
        return (
          <>
            <div className="flex mb-2 items-center">
              <div className="icon_smaller round mr-2" style={{backgroundColor: colors[m.groupNr]}}/>
              <h3>{groupName[m.groupNr]}</h3>
            </div>
            {
              this.api.profile.userDepartments.departments.filter(department => department.selected[m.groupNr])
                .map(department => {
                  return (
                    <div className="mt-1" key={"show_department_" + department.id}>
                      {department.name}
                    </div>
                  )
                })
            }
          </>
        )
      } else if (m.name === 'total_potential') {
        return (
          <p>The overall potential of all areas together</p>
        )
      } else if (m.name === 'zebrain') {
        return (
          <div style={{width: "300px"}}>
            <div className="flex items-center">
              <div className="icon_smaller round mr-2" style={{backgroundColor: colorAll}}/>
              <h3>Zebrain</h3>
            </div>

            <p className="mt-4">Results from this group is based on data from all our customers together. About 1700 potential
              measurements from approximately 1100 users.</p>
          </div>

        )
      } else if (m.name === 'range') {
        return (
          <p>The spread between the lowest and highest value</p>
        )
      } else {
        let current = 0
        let desired = 0
        let gap
        let title = 'Missing data'
        let groupTitle = groupName[m.groupNr]
        let color = colors[m.groupNr]
        if (m.name === 'total') {
          title = 'Total potential'
          current = this.props.stats.customerSummaryList[m.groupNr].current
          desired = this.props.stats.customerSummaryList[m.groupNr].desired
        } else if (m.name === 'area') {
          const groupNrI = areaNrFromPos(m.areaNr)
          title = areas[groupNrI].name
          current = this.props.stats.customerSummaryList[m.groupNr].areas[m.areaNr].current
          desired = this.props.stats.customerSummaryList[m.groupNr].areas[m.areaNr].desired
        } else if (m.name === 'average') {
          color = colorAll
          groupTitle = 'Zebrain'
          if (m.areaNr === -1) {
            title = 'Total Potential'
            current = this.props.stats.all.current
            desired = this.props.stats.all.desired
          } else {
            const groupNrI = areaNrFromPos(m.areaNr)
            title = areas[groupNrI].name
            current = this.props.stats.all.areas[m.areaNr].current
            desired = this.props.stats.all.areas[m.areaNr].desired
          }
        }
        gap = desired - current
        return (
          <>
            <h3>{title}</h3>
            <div className="mt-2 flex items-center">
              <div className="icon_small round" style={{backgroundColor: color}}/>
              <h4 className="ml-2">{groupTitle}</h4>
            </div>
            <p className="mt-4">Current state: {current.toFixed(0)}%</p>
            <p className="mt-1">Desired state: {desired.toFixed(0)}%</p>
            <p className="mt-1">Desired change: {gap.toFixed(0)}pp</p>
          </>
        )
      }
    }

    return (
      <>
        <h3>Missing content</h3>
        <p className="mt-4">Bad parameters</p>
      </>
    )
  }

  render () {
    const breadcrumbs: Array<BreadcrumbInterface> = [
      {
        name: 'Overview',
        link: '/'
      },
      {
        name: 'Heatmap',
        link: '/heatmap'
      }
    ]
    let name = "Total Potential"
    let breadCrumbName = "Potential"
    if (this.state.areaNr > 0 && this.state.areaNr < 6) {
      breadcrumbs.push({
        name: 'Potential',
        link: '/heatmap/potential'
      })
      name = breadCrumbName = areas[this.state.areaNr].name
    }
    return (
      <IonPage>
        <IonContent>
          <Header name={breadCrumbName} breadcrumbs={breadcrumbs} reload={this.props.reload}/>
          {
            this.state.mouseOver &&
              <div id="mouse_over">
                  <div className="mouse_arrow"/>
                  <div className="p-4 bg_white border_radius">
                    {this.renderMouseOver()}
                  </div>
              </div>
          }
          <div className="page_content">
            <div className="page_section">
              <h1>Potential</h1>

              <div className="page_bar mt-2"/>
              <div className="flex justify-between">
                <div>
                  <IonSegment className="light page_intro_text top_margin" value={this.state.subPage} mode="ios"
                              onIonChange={(e) => this.changeSubpage(e.detail.value)}>
                    <IonSegmentButton value="0">
                      <IonLabel>Total potential</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="1">
                      <IonLabel>Self insight</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="2">
                      <IonLabel>Basic energy</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="3">
                      <IonLabel>Motivation</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="4">
                      <IonLabel>Self leadership</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="5">
                      <IonLabel>Compare all</IonLabel>
                    </IonSegmentButton>
                  </IonSegment>

                  <div className="mt-10 flex items-center">
                    <h2>{name}</h2>
                    {
                      this.state.areaNr === 0 &&
                        <img alt='help' className="ml-2 icon_h2" src="/assets/icon/question_mark.svg"
                             onMouseEnter={(e) =>
                               this.mouseEnter(e, 'total_potential', this.state.areaNr, 0)}
                             onMouseLeave={this.mouseLeave}
                        />
                    }
                  </div>

                  <div className="mt-4 flex items-center">
                    <IonToggle checked={this.state.showAverage} mode="ios" className="toggle_small"
                               onIonChange={(e) => this.setState({showAverage: !!e.detail.checked})}
                               />
                    <p className="ml-2">{this.state.showAverage ? "Hide": "Show"} average in Zebrain platform</p>
                  </div>

                  <div className="mt-10">
                    {this.showUsers()}
                  </div>

                  <div className="top_margin">
                    {this.showBarCharts()}
                  </div>

                  <div className="mt-8">
                  {
                    this.state.areaNr < 5 ? this.showPotentialTable(this.state.areaNr, "State") :
                      this.showPotentialTables()
                  }
                  </div>

                </div>

                {this.showInfoBoxes()}

              </div>
            </div>

            {
              (this.state.areaNr > 0 && this.state.areaNr < 5) ?
                this.showOneArea(this.state.areaNr) :
                this.showTotal()
            }

          </div>
        </IonContent>
      </IonPage>
    )
  }
}
