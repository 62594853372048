import {IonContent, IonPage} from '@ionic/react';
import React from "react";
import Header from "../../components/header/Header";
import {BreadcrumbInterface} from "../../components/header/interfaces";
import Api from "../../services/Api";
import {connect, ConnectedProps} from "react-redux";
import {sortTable} from "../../helpers";
import HelpModal from "../../components/help/HelpModal";
import {formatMeetingStatusNew} from "./helpers";
import {CoachRequestInterface} from "./interfaces";

interface ClientInterface {
  id: number
  email: string
  firstName: string
  lastName: string
  createdAt: string
  invitedToCoach: string
}

interface LicenseInterface {
  name: string
  start: string
  end: string
  status: string
  isActive: boolean
  coachIncluded: boolean
  meetingCount: number
  meetingsIdeal: number
  meetingsConsumed: number
  meetingsBookedPeriod: number
  meetingsBookedTotal: number
  meetingsCancelled: number
  coachRequestStatus: CoachRequestInterface
}

const mapState = (state: any) => ({
  navigation: state.navigation
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>


interface ComponentState {
  loaded: boolean
  client: ClientInterface
  licenses: Array<LicenseInterface>
  error: string
}



class Client extends React.Component<PropsFromRedux, ComponentState>  {

  api = Api.getInstance()
  sortOrders: Array<boolean> = [true, false, false, false, false, false, false, false]

  state = {
    loaded: false,
    client: {} as ClientInterface,
    licenses: [] as Array<LicenseInterface>,
    error: ''
  }

  componentDidMount() {
   this.getData()
  }

  getData = () => {
    this.setState({loaded: false})
    if (this.api.profile.customer) {
      const url = 'v1/client/' + this.api.profile.customer.id + '/' + this.props.navigation.sub1
      this.api.get(url, ['client_not_found']).then(response => {
        this.setState({
          loaded: true,
          client: response.json.client,
          licenses: response.json.licenses,
          error: response.json.error
        })
      }).catch(response => {
        if (response.isExpectedError) {
          this.setState({error: 'You do not have access to this page.'})
        }
      });
    }
  }

  sortCol = (col: number, tableId: string) => {
    this.sortOrders[col] = !this.sortOrders[col]
    sortTable(tableId, col, this.sortOrders[col])
  }

  showLicenseStatus = (license: LicenseInterface) => {
    let status = ""
    let statusClass = ""
    if (license.meetingsConsumed >= license.meetingsIdeal) {
      status = "Client has consumed " + license.meetingsConsumed + ", which is ideal."
    } else if ((license.meetingsConsumed + license.meetingsBookedPeriod) >= license.meetingsIdeal) {
      status = "Client has consumed " + license.meetingsConsumed + " and booked " + license.meetingsBookedPeriod +
        " which is ideal."
    } else if ((license.meetingsConsumed + license.meetingsBookedTotal) >= license.meetingCount) {
      status = "Client has consumed " + license.meetingsConsumed + " and booked a total of " +
        license.meetingsBookedTotal + " meetings. A bit behind at the moment, but will catch up."
    } else {
      status = "But at this period end the client has consumed " + license.meetingsConsumed + " and only booked " +
        license.meetingsBookedTotal + " meetings."
      if ((license.meetingsIdeal - license.meetingsConsumed - license.meetingsBookedTotal) > 1) {
        statusClass = "warn"
      }
    }

    return (
      <div className={"meeting_status_box " + statusClass}>
        <div className="flex items-center">
          Client should ideally have had or booked {license.meetingsIdeal} meetings this period.
          <HelpModal name="meeting_period" />
        </div>
        <p className="mt-4">
          {status}
        </p>
      </div>
    )

  }

  render () {
    let showError = ''
    let name = 'User'
    if (!this.state.loaded) {
      if (this.state.error && this.state.error.length > 0) {
        showError = this.state.error
      } else {
        showError = 'Loading...'
      }
    } else {
      name = this.state.client.firstName + ' ' + this.state.client.lastName
    }
    const breadcrumbs: Array<BreadcrumbInterface> = [
      {
        name: 'Overview',
        link: '/'
      },
      {
        name: 'Users',
        link: '/users'
      }
    ]

    return (
      <IonPage>
        <IonContent>
          <Header name={name}
                  breadcrumbs={breadcrumbs} reload={this.getData}/>
          <div className="page_content">

              {
                showError.length > 0 ? (
                  <div className="page_section">
                    {showError}
                  </div>
                ) : (
                  <>
                  <div className="page_section">
                    <h1 data-private>{name}</h1>

                    <div style={{ maxWidth: '605px' }} className="page_title_bar" />
                    <table className="data_table">
                      <tbody>
                      <tr>
                        <th>Email</th>
                        <td data-private>{this.state.client.email}</td>
                      </tr>
                      <tr>
                        <th>Onboarded</th>
                        <td>{this.state.client.createdAt.substring(0, 10)}</td>
                      </tr>
                      </tbody>

                    </table>
                    <h2 className="mt-4">Licenses</h2>


                    {
                      this.state.licenses.map((license, i) => {
                        return (
                        <div key={"license_" + i}>
                          <div style={{ maxWidth: '605px' }} className="page_title_bar" />
                          <h3  className="mt-4">
                            {license.name} ({license.isActive ? 'active' : 'inactive'})
                          </h3>
                          <table className="data_table mt-4">
                            <tbody>
                            <tr>
                              <td>License start</td>
                              <td>{license.start}</td>
                            </tr>
                            <tr>
                              <td>License end</td>
                              <td>{license.end ? license.end.substring(0, 10) : "No end"}</td>
                            </tr>
                            {
                              (license.isActive && this.state.client.invitedToCoach.length > 10) &&
                                <>
                                  <tr>
                                    <td className="flex items-center">
                                      Invited to upgrade
                                      <HelpModal name="invitation_upgrade" />
                                    </td>
                                    <td>{this.state.client.invitedToCoach.substring(0, 10)}</td>
                                  </tr>
                                </>
                            }
                            {
                              license.coachRequestStatus?.createdAt &&
                              <>
                                <tr>
                                  <td>Has requested coach</td>
                                  <td>
                                    {license.coachRequestStatus.createdAt.substring(0,10)}
                                  </td>
                                </tr>
                              </>
                            }
                            {
                              (license.coachIncluded && license.meetingCount) &&
                              <>
                                <tr>
                                  <td>Meetings included</td>
                                  <td>{license.meetingCount}</td>
                                </tr>
                                <tr>
                                  <td className="flex items-center">
                                      Meetings consumed
                                      <HelpModal name="meeting_consumed" />
                                  </td>
                                  <td>{license.meetingsConsumed}</td>
                                </tr>
                                <tr>
                                    <td className="flex items-center">
                                        Meetings done
                                        <HelpModal name="meeting_done" />
                                    </td>
                                    <td>{(license.meetingsConsumed - license.meetingsCancelled)}</td>
                                </tr>
                                <tr>
                                    <td className="flex items-center">
                                        Meetings cancelled
                                        <HelpModal name="meeting_cancelled" />
                                    </td>
                                  <td>{license.meetingsCancelled}</td>
                                </tr>
                                <tr>
                                    <td className="flex items-center">
                                        Upcoming meetings
                                        <HelpModal name="meeting_booked" />
                                    </td>
                                    <td>{license.meetingsBookedTotal}</td>
                                </tr>
                                {
                                  license.isActive &&
                                    <tr>
                                        <td>Meeting status</td>
                                        <td>{formatMeetingStatusNew(license.start, license.end, license.meetingCount,
                                          license.meetingsConsumed, license.meetingsBookedPeriod)}</td>
                                    </tr>
                                }

                              </>
                            }
                            </tbody>
                          </table>
                          {
                            (license.coachIncluded && license.isActive && license.meetingCount)
                            && this.showLicenseStatus(license)
                          }
                        </div>
                      )
                    })
                  }
                  </div>
                  </>
                )
              }
          </div>
        </IonContent>
      </IonPage>
    )
  }
}

export default connector(Client)
