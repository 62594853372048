import React from "react";
import {IonModal} from "@ionic/react";

interface ComponentProps {
  name: string
}

interface ComponentState {
  openModal: boolean
}

export default class HelpModal extends React.Component<ComponentProps, ComponentState>  {

  state = {
    openModal: false
  }

  renderModal = () => {
    return (
      <div className="p-4">
        <div className="flex justify-end">
          <img className="cursor-pointer display_block icon_medium"
               onClick={() => this.setState({openModal: false})}
               alt="help" src="/assets/icon/close-circle-outline.svg"/>
        </div>
        <div className="mx-4">
          {this.renderHelpContent()}
        </div>
      </div>
    )
  }

  renderHelpContent = () => {
    if (this.props.name === 'users_with_coach') {
      return (
        <>
          <h2>Users with coach</h2>
          <p className="mt-4">
            Users with an active license that gives access to a Zebrain coach.
          </p>
          <p className="mt-4">
            The max-figure refers to the maximum number of users allowed coach access.
          </p>
          <p className="mt-4">
            These users can schedule meetings and chat with their coach.
          </p>
          <p className="mt-4">
            The <b>Meetings</b> column shows if the user is on track with the meeting schedule.
          </p>
        </>
      )
    } else if (this.props.name === 'users_with_platform') {
      return (
        <>
          <h2>Users with platform access</h2>
          <p className="mt-4">
            These users can work with their measurements and development areas, but do not have access to a coach.
          </p>
          <p className="mt-4">
            The max-figure refers to the maximum number of users allowed platform access.
          </p>
          <p className="mt-4">
            The <b>Requested coach</b> column shows if the user wants to upgrade to a coach license.
          </p>
          <p className="mt-4">
            The <b>Invited to coach</b> column shows if the user has been sent a mail with an invitation to
            upgrade to a coach license.
          </p>
        </>
      )
    } else if (this.props.name === 'users_without_license') {
      return (
        <>
          <h2>Users without license</h2>
          <p className="mt-4">
            These users no longer have an active license.
          </p>
          <p className="mt-4">
            They can still work with measurements and development areas.
          </p>
        </>
      )
    } else if (this.props.name === 'users_onboarding') {
      return (
        <>
          <h2>Users onboarding</h2>
          <p className="mt-4">
            These users have created an account, but are not finished with the onboarding.
          </p>
          <p className="mt-4">
            A user that is stuck in onboarding is still using up a license.
            </p>
          <p className="mt-4">
            You could ask the user if they need help to finalize the onboarding.
          </p>
           <p className="mt-4">
             Otherwise you could ask Zebrain to pass the license to another user.
          </p>
        </>
      )
    } else if (this.props.name === 'users_invited_not_started') {
      return (
        <>
          <h2>Users invited but not started</h2>
          <p className="mt-4">
            These users have received an email with a license invitation,
            but they have not yet created a Zebrain account
          </p>
        </>
      )
    } else if (this.props.name === 'users_upgrade_invited_not_started') {
      return (
        <>
          <h2>Users invited to upgrade license but not upgrade</h2>
          <p className="mt-4">
            These users have received an email with a invitation to upgrade license,
            but they have not yet claimed that license
          </p>
        </>
      )
    } else if (this.props.name === 'meeting_period') {
      return (
        <>
          <h2>Meeting period</h2>
          <p className="mt-4">
            A meeting period is the duration of the license divided by number of meetings the client should have.
          </p>
          <div className="mt-4">
            <p><i>Example:</i></p>
            <p className="mt-2">
              If a client is allowed four meetings and the duration of the license is eight weeks, each meeting period
              is two weeks.
            </p>

          </div>
          <p className="mt-4">
            Ideally a client should have one meeting per period. When the system calculates if a client is on track
            or behind schedule, it looks at what period the client is in and then checks how many meetings the client
            has had or booked before the end of current period.
          </p>
        </>
      )
    } else if (this.props.name === 'meeting_consumed') {
      return (
        <>
          <h2>Meetings consumed</h2>
          <p className="mt-4">
            A meeting is consumed if either the meeting took place according to plan, or if client cancelled the
            meeting less than 24 hours before the meeting started.
          </p>
          <p className="mt-4">
            If a meeting is cancelled due to technical problems in the Zebrain platform, or if the coach
            missed the meeting, the meeting is not regarded as consumed. The client will get another meeting at no cost.
          </p>
        </>
      )
    } else if (this.props.name === 'meeting_done') {
      return (
        <>
          <h2>Meetings done</h2>
          <p className="mt-4">
            A meeting is done if the meeting took place according to plan.
          </p>
        </>
      )
    } else if (this.props.name === 'meeting_booked') {
      return (
        <>
          <h2>Meetings booked</h2>
          <p className="mt-4">
            A scheduled meeting that has not taken place yet.
          </p>
        </>
      )
    } else if (this.props.name === 'meeting_cancelled') {
      return (
        <>
          <h2>Meeting cancelled</h2>
          <p className="mt-4">
            If a client cancels the meeting less than 24 hours before the meeting starts, the meeting is regarded
            as consumed.
          </p>
          <p className="mt-4">
            The same principle applies if the client does not show up in the meeting.
          </p>
        </>
      )
    } else if (this.props.name === 'invitation_upgrade') {
      return (
        <>
          <h2>Invited to upgrade</h2>
          <p className="mt-4">
            This client has received a mail with an invitation to upgrade to a license that includes coaching.
          </p>
        </>
      )
    }

    return (
      <>
        <h2>Error</h2>
        <p className="mt-4">
          Missing help section for {this.props.name}
        </p>
      </>
    )
  }

  render () {
    return (
      <div className="inline_block">
        <IonModal isOpen={this.state.openModal}
                  onDidDismiss={() => this.setState({openModal: false})}>
          {
            this.renderModal()
          }
        </IonModal>
        <img className="cursor-pointer display_block icon_medium ml-4"
             onClick={() => this.setState({openModal: true})}
             alt="help" src="/assets/icon/help-circle-outline.svg"/>
      </div>
    )
  }
}
