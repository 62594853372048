export const err_language_en = {
  'login_failed': 'Wrong login credentials',
  'freja_invalid_userinfo': 'Wrong or unknown personal identity number',
  'freja_unknown_user': 'Wrong or unknown personal identity number',
  'freja_service_disabled': 'You have de-activated this service',
  'freja_auth_failed': 'Another login session has already started. Try again',
  'freja_invalid_ref': 'It was not possible to login with Freja eID',
  'email_in_use': 'The email address is already used by another account',
  'invalid_email': 'You must enter a valid email address',
  'have_meeting_booked': 'You cannot end your plan since you have upcoming meetings booked',
  'license_code_not_found': 'Could not find the campaign code',
  'no_seats_available': 'There are no available seats for this license',
  'license_expired': 'The license has expired',
  'invitation_expired': 'The invitation has expired',
  'invitation_not_found': 'Invitation not found',
  'firstname_error': 'Fill out first name',
  'lastname_error': 'Fill out last name',
  'city_error': 'Fill out city',
  'email_error': 'Fill out email',
  'native_language_error': 'Choose a native language',
  'native_country': 'Choose a home country',
  'language_error': 'Choose at least one language',
  'too_big_img_error': 'This file is too large. Please choose a file that is smaller than 2 MB.',
  'too_long_error': 'Too many characters.',
  'org_nr_error': 'Fill out organisation number',
  'membership_error': 'Choose a coach membership',
  'other_membership_error': 'Fill out other membership',
  'certificate_error': 'Choose a coach certificate',
  'other_certificate_error': 'Fill out another coach certificate',
  'coach_hour_error': 'Number of hours should be between 0 and 8000.',
  'management_error': 'Number of years should be between 0 and 80.',
  'certified_since_error': 'Date should be in the format YYYY-MM-DD',
  'pause_error': 'Pause should be between 0 and 60 minutes.',
  'min_time_error': 'Earliest meeting can be between 0 and 24 hours.',
  'upload_error': 'Upload a certificate',
  'form_missing_error': 'Some information is missing, please fill out the rest of the form.',
  'area_error': 'Choose at least one coaching area',
  'form_error': 'At least one field has too much text, please shorten it',
  'form_errors': 'There are errors in the form above.',
}
