import { IonContent, IonPage } from '@ionic/react';
import './Heatmap.css';
import React from "react";
import Header from "../../components/header/Header";
import { BreadcrumbInterface } from "../../components/header/interfaces";
import Api from "../../services/Api";
import {
  BehaviourInterface,
  NameValueInterface,
} from "./interfaces";
import DisaRomb from "./DisaRomb";
import BarChart from "./BarChart";

interface ComponentProps {
  navigation: any
  behaviours: BehaviourInterface
  reload: Function
}

interface ComponentState {}

export default class HeatmapBehaviour extends React.Component<ComponentProps, ComponentState>  {

  api = Api.getInstance()

  componentDidMount() {
    this.api.get('v1/log/' + this.api.profile.customer?.id + '/' + 8).then(_result => {
      // console.log(_result.json)
    })
  }

  displayBarValue = (value:any) => {
    let newValue = value.toFixed(1) - 1.5;
    return newValue + '%';
  }

  render() {
    const breadcrumbs: Array<BreadcrumbInterface> = [
      {
        name: 'Overview',
        link: '/'
      },
      {
        name: 'Heatmap',
        link: '/heatmap'
      }
    ]
    return (
      <IonPage>
        <IonContent>
          <Header name="Behavioural profile" breadcrumbs={breadcrumbs} reload={this.props.reload}/>
          <div className="page_content">
            <div className="page_section">
              <h1>The group’s average behavioural profile</h1>
              <div className="page_title_bar" />
              <div className='page_intro_text'>
                <h2 className="top_margin">If {this.api.profile.customer?.customerName} was a person...</h2>
                <p className='top_margin'>
                  The group’s mutual behavioural profile is a visual compilation of the group's <i>average</i> result,
                  based on the behavioural profiles of every individual in the group.
                </p>
                <p className="top_margin">
                  Zebrain’s behaviour analysis is based on four foundational behaviours that all humans have.
                  As humans, we don’t have just one behaviour style – we have access to all four of them.
                  However, everybody uses and displays different amounts of these behaviours.
                </p>
                <p className="top_margin">
                  There is no right or wrong when it comes to behavioural profiles. Rather, they should be seen as
                  providing understanding and insights about how an individual or group is behaviourally composed,
                  and what needs follow from that.
                </p>
                <p className="top_margin">
                  Below is a summary of how the average mutual behavioural profile looks like within your
                  specific group.
                </p>
              </div>
              <div className="flex top_margin page_section_direction">
                <div className="page_half_section" style={{'maxWidth' : '300px'}}>
                {
                    this.props.behaviours.styles.map((style: NameValueInterface, i: number) => {
                    return (
                      <div key={"style_" + i} className='flex top_margin'>
                        <img src={`/assets/icon/heatmap/` + style.name + `.svg`} height={'50px'} alt="" />
                        <div className="left_margin">
                          <h3>{style.name} behaviour</h3>
                          <h1>{style.value.toFixed(1)}%</h1>
                        </div>
                      </div>
                    )
                  })
                }
                </div>
                <div className="page_half_section relative r_top_margin">
                  <DisaRomb disa={this.props.behaviours.styles} color="cerise" />
                </div>
              </div>
            </div>

            <div className="page_section top_margin">
              <h2>About the different behavioural styles</h2>
              <div className="flex top_margin page_section_direction">
                <div className="page_half_section">
                  <div className="flex">
                    <img src="/assets/icon/pink/boosting.svg" height={'50px'} alt="" />
                    <div className='left_margin'>
                      <h4>Boosting behaviour</h4>
                      <p>
                        When we use our &quot;Boosting behaviour&quot; we are focused on influencing
                        others with strong experiences and things that can be perceived as exciting. When this behaviour is
                        used, details and facts become uninteresting and boring. Our &quot;Boosting behaviour&quot; makes
                        us flexible and optimistic and we highlight opportunities rather than difficulties.
                      </p>
                    </div>
                  </div>
                  <div className="flex top_margin">
                    <img src="/assets/icon/pink/driving.svg" height={'50px'} alt="" />
                    <div className='left_margin'>
                      <h4>Driving behaviour</h4>
                      <p>
                        When we use our &quot;Driving behaviour&quot; we take the initiative, we want
                        to decide, have control and achieve concrete, clear and measurable results. The control is more about
                        power, than attention to details. Our &quot;Driving behaviour&quot; makes sure we keep a high pace
                        and get things done as quickly as possible.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="page_half_section r_top_margin r_left_margin">
                  <div className="flex">
                    <img src="/assets/icon/pink/supporting.svg" height={'50px'} alt="" />
                    <div className='left_margin'>
                      <h4>Supporting behaviour</h4>
                      <p>
                        When we use our &quot;Supporting behaviour&quot; we are aware of the needs of
                        others, thrive with others and are good at seeing the positive in what is right now. We are
                        persistent and complete what we have started, even if it feels reluctant. We are calm and good at
                        listening to other people.
                      </p>
                    </div>
                  </div>
                  <div className="flex top_margin">
                    <img src="/assets/icon/pink/detailed.svg" height={'50px'} alt="" />
                    <div className='left_margin'>
                      <h4>Detailed behaviour</h4>
                      <p>
                        When we use our &quot;Detailed behaviour&quot; we focus on facts and details
                        and aim to do everything correctly. We are logical and place high demands on quality. Our
                        &quot;Detailed behaviour&quot; means that we work systematically and process all the information
                        available, before we make a decision.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="page_section top_margin">
              <h2>Insights on the group's behavioural analysis</h2>
              <div style={{ maxWidth: '620px' }}>
                Below is a summary of your group's behavioural analysis which your group has answered individually
                and summarises the average values for your group. Their individual answers are their personal opinion
                about themselves.
              </div>
              {
                this.props.behaviours.answers.map((answer: NameValueInterface, i: number) => {
                  return (
                    <div key={"answer_" + i} className={"top_margin " } style={{'maxWidth' : '805px', 'marginTop': '72px' }}>
                      <h3>
                        {answer.name}
                      </h3>
                      <div className="top_margin">
                        <div className='font_small' style={{'marginLeft' : this.displayBarValue(answer.value), 'marginBottom': '3px' }}>{answer.value.toFixed(0)}</div>
                        <BarChart value={answer.value} size="small" />
                        <div className="flex justify-between top_margin">
                          <div className="font_small">Inaccurate</div>
                          <div className="font_small">Accurate</div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>

            <div className="page_section top_margin">
              <h2>About our behavioural algorithm</h2>
              <div className="flex page_section_direction top_margin">
                <div className="page_half_section">
                  There are no connections between our personalities or our different behavioural patterns and how
                  successful or happy we are or can become. Therefore, models that measure behavioural styles should
                  not be used as a basis for this type of assessment. On the other hand, our chances of achieving
                  what we want, increase the more we know what is important to us, where we are going, our strengths,
                  our limitations and how we affect others. The more self-insight the greater the opportunities.
                </div>
                <div className="page_half_section r_left_margin r_top_margin">
                  Our matching algorithm is based on Big Five, Revised NEO Personality Inventory, HEXACO Model of
                  Personality, Social Styles, IDI Interpersonal Dynamics Inventory, STC Systems Centered Therapy,
                  SCM Stereotype Content Model. Our algorithms comprise four types of behaviours which all people have
                  and use differently, and are based on extensive research in both neurophysiology and personality
                  psychology.
                </div>
              </div>
              <h2 className="top_margin">The research behind</h2>
              <div className="flex page_section_direction top_margin">
                <div className="page_half_section">
                  <h4>Big Five.</h4>
                  <p>
                    The world's most widely used research-based personality models and tests to
                    predict different behaviours and behaviour patterns.
                  </p>
                  <h4 className="top_margin">Revised NEO Personality Inventory.</h4>
                  <p>
                    Further development of Big Five with subcategories.
                  </p>
                  <h4 className="top_margin">Hexaco model of personality.</h4>
                  <p>
                    Big Five with a sixth factor, Honesty and Humanity.
                  </p>
                  <h4 className="top_margin">Social Styles.</h4>
                  <p>
                    Shows, among other things, the importance of adapting to and meeting others
                    who do not resemble oneself. Merril or Reid.
                  </p>
                  <h4 className="top_margin">IDI Interpersonal Dynamic Inventory.</h4>
                  <p>
                    Further development of Social Styles which, among other
                    things, describe the impressions we make on each other. R Zackrison et al.
                  </p>
                  <h4 className="top_margin">SCM, Stereotype Content Model.</h4>
                  <p>
                    How we judge others, instinctively, based on stereotypes. Amy Cuddy.
                  </p>
                </div>
                <div className="page_half_section r_left_margin r_top_margin">
                  <h4>STC; Systems Centered Therapy.</h4>
                  <p>
                    Yvonne Agazarin's model and her work in resolving conflicts
                    and creating consensus between different individuals and groups.
                  </p>
                  <h4 className="top_margin">Helen Fisher.</h4>
                  <p>
                    Helen Fisher's research together with brain researcher Lucy L. Brown and psychologist Art Aron
                    based on analysis of biologically based personality dimensions in 40,000 individuals and patterns of
                    attractions in 28,000 individuals as well as data from genetics, neurology and personality psychology.
                  </p>
                  <h4 className="top_margin">Giacomo Rizzolatti.</h4>
                  <p>
                    Giacomo Rizzolatti’s research about mirror neurons which shows how we affect each other
                    with our emotions and the importance of emotional intelligence EQ.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </IonContent>
      </IonPage>
    )
  }
}
