import React from "react";
import {changeUrl} from "../../services/Navigation";
import './Header.css';
import {BreadcrumbInterface} from "./interfaces";
import Api from "../../services/Api";
import SelectDepartments from "./SelectDepartments";

interface ComponentProps {
  name: string
  breadcrumbs?: Array<BreadcrumbInterface>
  reload?: Function
}

interface ComponentState {}

export default class Header extends React.Component<ComponentProps, ComponentState>  {

  api = Api.getInstance()
  groups = [0, 1, 2, 3]

  showBack = () => {
    if (this.props.breadcrumbs && this.props.breadcrumbs.length > 0) {
      const breadcrumb = this.props.breadcrumbs[this.props.breadcrumbs.length - 1]
      return (
        <div className="icon_small" onClick={() => this.followLink(breadcrumb)}>
          <img alt="back" src="/assets/icon/chevron-back-outline.svg"/>
        </div>
      )
    }
    return (
      <></>
    )
  }

  followLink = (breadcrumb: BreadcrumbInterface) => {
    if (breadcrumb.linkFunc) {
      breadcrumb.linkFunc()
    } else {
      changeUrl(breadcrumb.link)
    }
  }

  saveDepartments = () => {
    if (this.props.reload) {
      this.props.reload()
    }
  }

  render () {
    return (
      <>
        <div className="header flex justify-between items-center">

          <div className="header_left flex justify-center items-center flex-shrink">
            <img alt="logo" className="icon_small" src="/assets/icon/logo.svg"/>
          </div>
          <div className="header_breadcrumbs">
              <div className="header_breadcrumbs_small">
                {this.showBack()}
                <div className="header_breadcrumbs_name">
                  {this.props.name}
                </div>
              </div>
              <div className="header_breadcrumbs_large">
                {
                  this.props.breadcrumbs?.map((breadcrumb, index) => {
                    return (
                      <div className="cursor-pointer mr-2" key={'br_l_' + index}
                           onClick={() => this.followLink(breadcrumb)}>
                        {breadcrumb.name} &nbsp;&gt;
                      </div>
                    )
                  })
                }
                <div className="header_breadcrumbs_name">
                  {this.props.name}
                </div>
              </div>
          </div>

          {
            this.api.loggedIn &&
              <div className="header_right">

                {
                  (this.api.profile.customer && this.api.profile.customers.length > 1) ? (
                    <span className="ml-2 underline cursor-pointer" onClick={() => this.api.removeCustomer()}>
                      {this.api.profile.customer?.customerName}
                    </span>
                    ) : (
                      <>
                        {this.api.profile.customer?.customerName}
                      </>
                    )
                }
                <span className="ml-2 underline cursor-pointer" onClick={() => this.api.logOut('/')}>Log out</span>
              </div>
          }
        </div>

        {
          (this.api.loggedIn && this.api.profile.userDepartments?.departments?.length > 0) &&
          <div className="header_filter_section page_section">
              <SelectDepartments close={() => this.saveDepartments()}/>
          </div>
        }
      </>
    )
  }
}
