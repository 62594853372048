import React from 'react'

interface ComponentProps{
  value: number
  color?: string
}

interface ComponentState {
  angleDeg: number
}

export default class SpeedoMeter extends React.Component<ComponentProps, ComponentState>  {

  colorStart = '#ffccc8'
  colorEnd = '#bc80f0'
  arc = ''
  startArc = ''
  arrow = ''
  angle = 0
  randId = 'to_be_generated'

  state = {
    angleDeg: 0
  }

  componentDidMount() {
    this.setPaths()
  }

  setPaths = () => {
    this.randId = this.createRandomId()
    let value = this.props.value
    if (value === 100) {
      value = 99.9
    } else if (value === -100) {
      value = -99.9
    }
    this.angle = 2 * Math.PI * value / 100
    const angleDeg = 360 * value / 100

    let largeArc = 0
    let largeArcInner = 0
    let sweep = this.angle > 0 ? 1 : 0
    const endXouter = 50 + 48 * Math.sin(this.angle)
    const endYouter = 50 - 48 * Math.cos(this.angle)
    const endXinner = 50 + 20 * Math.sin(this.angle)
    const endYinner = 50 - 20 * Math.cos(this.angle)
    if (value > 50 || value < -50) {
      largeArc = 1
    }

    this.arc = 'M 50 2 A 48 48 0 ' + largeArc + ' ' + sweep + ' ' + endXouter + ' ' + endYouter
      + ' L ' + endXinner + ' ' + endYinner + ' A 20 20 0 '  + largeArcInner + ' ' + sweep + ' 50 30 z'

    this.arrow = 'M 50.1 0 L 53 50.1 A 3 3 0 1 1 47 50.1 L 49.1 0 z'
    // this.setState({randomNumber: Math.random()})
    this.setState({angleDeg: angleDeg})
  }

  createRandomId = () => {
    return "clicp_" + Math.random()
  }

  componentDidUpdate(prevProps:ComponentProps, prevState:ComponentState, snapshot:any) {
    // If Route has changed, update state (Ensures consistency between location state and Component state).
    if (prevProps.value && this.props.value !== prevProps.value) {
      this.setPaths()
    }
  }

  render() {

    const randUrl = "url(#" + this.randId + ")"
    const rotate = "rotate(" + this.state.angleDeg + ", 50, 50)"
    let startAngle = this.state.angleDeg >= 0 ? 0 : (360 + this.state.angleDeg)
    let stopAngle = this.state.angleDeg <= 0 ? 360 : this.state.angleDeg
    let startColor = this.props.color ? this.props.color : this.colorStart
    let stopColor = this.props.color ? this.props.color : this.colorEnd
    if (this.state.angleDeg < 0) {
      startColor = "#cc0000"
      stopColor = this.props.color ? this.props.color : this.colorEnd
    }
    const conicBackground = "conic-gradient(from 0deg at 50% 50%, " + startColor + " " + startAngle + "deg, "
      + stopColor + "  " + stopAngle + "deg)"

    return (

      <svg viewBox="0 0 100 100"
           xmlns="http://www.w3.org/2000/svg">
        <clipPath id={this.randId}>
          <path d={this.arc}/>
        </clipPath>

        <circle cx={50} cy={50} r={48} style={{fill: "#e5e5e9"}} />
        <foreignObject width="100" height="100" clipPath={randUrl}>
          <div style={{height: "100%", width: "100%", background: conicBackground}}/>
        </foreignObject>

        <circle cx={50} cy={50} r={20.5} fill="#ffffff"/>
        <path d={this.arrow} style={{fill: "#000026"}} transform={rotate}>
          <animateTransform attributeName="transform"
                            attributeType="XML"
                            type="rotate"
                            from="0 50 50"
                            to={this.state.angleDeg + " 50 50 "}
                            dur="1s"/>
        </path>

        <circle cx={50} cy={50} r={1.5} fill="#ffffff"/>
      </svg>
    )
  }
}
