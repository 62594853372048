import {IonContent, IonPage} from '@ionic/react';
import './Heatmap.css';
import React from "react";
import {changeUrl} from "../../services/Navigation";
import Header from "../../components/header/Header";
import {BreadcrumbInterface} from "../../components/header/interfaces";
import Api from "../../services/Api";
import {BehaviourInterface, StatsInterface} from "./interfaces";
import {connect, ConnectedProps} from "react-redux";
import HeatmapBehaviour from "./HeatmapBehaviour";
import HeatmapPotential from "./HeatmapPotential";
import HeatmapReadMore from './HeatmapReadMore';
import HeatmapProgress from "./HeatmapProgress";

const mapState = (state: any) => ({
  navigation: state.navigation
})

const connector = connect(mapState)

type PropsFromRedux = ConnectedProps<typeof connector>


interface ComponentState {
  behaviours: BehaviourInterface
  stats?: StatsInterface
  tooFew: boolean
  loaded: boolean
  dataVersion: number
}

class Heatmap extends React.Component<PropsFromRedux, ComponentState>  {

  api = Api.getInstance()

  state = {
    behaviours: {} as BehaviourInterface,
    stats: {} as StatsInterface,
    tooFew: false,
    loaded: false,
    dataVersion: 0
  }

  componentDidMount() {
   this.getData()
  }

  getData = () => {
    // this.setState({loaded: false})
    this.api.get('v1/heatmap/' + this.api.profile.customer?.id).then(response => {
      this.setState({
        behaviours: response.json.behaviours,
        stats: response.json.measureStats,
        tooFew: response.json.tooFew,
        dataVersion: this.state.dataVersion + 1,
        loaded: true
      })
    })
  }

  render () {
    const breadcrumbs: Array<BreadcrumbInterface> = [{
      name: 'Overview',
      link: '/'
    }]
    if (!this.state.loaded) {
      return (
        <IonPage>
          <IonContent>
            <Header name="Heatmap" breadcrumbs={breadcrumbs} />
            <div className="page_content">
              <div className="page_section">Loading...</div>
            </div>
          </IonContent>
        </IonPage>
      )
    }
    if (this.state.tooFew || !this.state.stats) {
      return (
        <IonPage>
          <IonContent>
            <Header name="Heatmap" breadcrumbs={breadcrumbs} reload={this.getData}/>
            <div className="page_content">
              <div className="page_section">
                <h1>Too few clients with measures</h1>
                <p className="top_margin">
                  We could not find enough measures to show the heatmap.
                </p>
                <p className="mt-4">
                  If you can see at least five onboarded users, talk
                  to <a href="mailto:support@zebrain.se">support@zebrain.se</a>. Sometimes a user has a measure that is
                  too old, or done with a test license.
                </p>
              </div>
            </div>
          </IonContent>
        </IonPage>
      )
    }
    if (this.props.navigation.sub1 === 'behaviour') {
      return (<HeatmapBehaviour navigation={this.props.navigation} behaviours={this.state.behaviours} reload={this.getData}/>)
    } else if (this.props.navigation.sub1 === 'potential') {
      return (<HeatmapPotential navigation={this.props.navigation} stats={this.state.stats} reload={this.getData}
                                dataVersion={this.state.dataVersion}/>)
    } else if (this.props.navigation.sub1 === 'progress') {
      return (<HeatmapProgress navigation={this.props.navigation} stats={this.state.stats} reload={this.getData}
                               dataVersion={this.state.dataVersion}/>)
    } else if (this.props.navigation.sub1 === 'read-more') {
      return (<HeatmapReadMore navigation={this.props.navigation} />)
    }


    return (
      <IonPage>
        <IonContent>
          <Header name="Heatmap" breadcrumbs={breadcrumbs} reload={this.getData}/>
          <div className="page_content">
            <div className="page_section">
              <h1>Heatmap</h1>

              <div style={{ maxWidth: '605px' }} className="page_title_bar" />

              <div style={{ maxWidth: '605px', margin: '25px 0' }} className="flex justify-between">
                <div className='right_margin'>
                  <h2>Behavioural profiles</h2>
                  <div className='top_margin'>See your group's behavioural profiles.</div>
                </div>
                <div className='align-center'>
                  <div className='button white inline_block' onClick={() => changeUrl('/heatmap/behaviour')}>
                    Read more
                  </div>   
                </div>
              </div>

              <div style={{ maxWidth: '605px' }} className="page_title_bar" />

              <div style={{ maxWidth: '605px', margin: '25px 0' }} className="flex justify-between">
                <div className='right_margin'>
                  <h2>Potential</h2>
                  <div className='top_margin'>See your group's total potential.</div>
                </div>
                <div className='align-center'>
                  <div className='button white inline_block' onClick={() => changeUrl('/heatmap/potential/0')}>
                    Read more
                  </div> 
                </div>
              </div>

              <div style={{ maxWidth: '605px' }} className="page_title_bar" />

              <div style={{ maxWidth: '605px', margin: '25px 0' }} className="flex justify-between">
                <div className='right_margin'>
                  <h2>Progress</h2>
                  <div className='top_margin'>See your group's progress.</div>
                </div>
                <div className='align-center'>
                  <div className='button white inline_block' onClick={() => changeUrl('/heatmap/progress/0')}>
                    Read more
                  </div>
                </div>
              </div>

              <div style={{ maxWidth: '605px' }} className="page_title_bar" />

              <div style={{ maxWidth: '605px', margin: '25px 0' }} className="flex justify-between">
                <div className='right_margin'>
                  <h2>Social Sustainability (ESG)</h2>
                  <div className='top_margin'>Track your KPI:s related to social sustainability</div>
                </div>
                <div className='align-center'>
                  <div className='button white inline_block' onClick={() => changeUrl('/esg')}>
                    Read more
                  </div>
                </div>

              </div>
              
              <div style={{ maxWidth: '605px' }} className="page_title_bar" />

              <div style={{ maxWidth: '605px', margin: '25px 0' }} className="flex justify-between">
                <div className='right_margin'>
                  <h2>About potential</h2>
                  <div className='top_margin'>Read more about our method and how it works.</div>
                </div>
                <div className='align-center'>
                  <div className='button white inline_block' onClick={() => changeUrl('/heatmap/read-more')}>
                    Read more
                  </div>
                </div>
              </div>              
              
              <div style={{ maxWidth: '605px' }} className="page_title_bar" />
            </div>
          </div>
        </IonContent>
      </IonPage>
    )
  }
}

export default connector(Heatmap)
