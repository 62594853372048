import React from 'react'

interface ComponentProps{
  value: number
  size: string
  oneColor?: boolean
  color?: string
  lightColor?: boolean
}

interface ComponentState {
  width: number
}

export default class BarChart extends React.Component<ComponentProps, ComponentState>  {

  barRef: any
  valueRef: any
  colorMin = '#4037A1'
  colorMax = '#FF00B8'
  lightMin = '#FFCCC8'
  lightMax = '#BC80F0'

  state = {
    width: 0,
  }

  constructor(props: ComponentProps) {
    super(props)
    this.barRef = React.createRef()
    this.valueRef = React.createRef()
  }

  componentDidMount() {
    this.setWidth()
  }

  setWidth = () => {
    this.valueRef.current.style.width = '0%'
    setTimeout(() => {
      if (!this.barRef.current) {
        console.log('No bar!')
        return
      }
      const width = this.barRef.current.offsetWidth
      const barWidth = this.props.value
      this.setState({width: width})
      this.valueRef.current.style.width = barWidth + '%'
    }, 500)
  }

  componentDidUpdate(prevProps:ComponentProps, prevState:ComponentState, snapshot:any) {
    if (this.props.value !== prevProps.value) {
      this.setWidth()
    }
  }

  render() {
    let backgroundImage: string
    if (this.props.color) {
      backgroundImage = this.props.color
    } else if (this.props.oneColor) {
      backgroundImage = this.colorMax
    } else if (this.props.lightColor) {
      backgroundImage = 'linear-gradient(to right, ' + this.lightMin + ', ' + this.lightMax + ' 100% ' + this.state.width + 'px)'
    } else {
      backgroundImage = 'linear-gradient(' + this.colorMin + ', ' + this.colorMax + ' 100% ' + this.state.width + 'px)'
    }

    return (        
      <div ref={this.barRef} className={"bar_background " + this.props.size}>
        <div ref={this.valueRef} className={"bar_animated " + this.props.size}
          style={{ background: backgroundImage }} />
      </div>
    )
  }
}
